import { onBeforeUnmount, onMounted, reactive, ref, Ref, toRefs, watch } from '@vue/composition-api';
import type { Data } from '@vue/composition-api';

import store from '@/store';

import Btn from '../Btn/index.vue';

import SvgInfo from '@/svg/info';
import SvgSuccess from '@/svg/success';
import SvgWarn from '@/svg/warn';
import SvgError from '@/svg/error';

interface Text {
	tip: string;
	notice: string;
	ok: string;
	cancel: string;
	yes: string;
	no: string;
	save: string;
	noSave: string;
}

interface TextLib {
	[propName: string]: Text;
}
export default {
	components: {
		SvgInfo,
		SvgSuccess,
		SvgWarn,
		SvgError,
		Btn,
	},
	setup(props: any, { root }: any) {
		//const t = root.$t.bind(root);
		//console.log(t);
		const language: Ref<string> = ref('zh');
		const textLib: TextLib = {
			zh: {
				tip: '提示',
				notice: '请注意',
				ok: '确定',
				cancel: '取消',
				yes: '是',
				no: '否',
				save: '保存',
				noSave: '不保存',
			},
			uyg: {
				tip: 'ئەسكەرتىش',
				notice: 'دىققەت قىلىڭ',
				ok: 'بولىدۇ',
				cancel: 'ئەمەلدىن',
				yes: 'شۇنداق',
				no: 'ياق',
				save: 'ساقلاش',
				noSave: 'ساقلىماسلىق',
			},
		};
		const text: Ref<Text> = ref(textLib[language.value]);
		watch(
			() => store.state.language,
			newValue => (text.value = textLib[newValue] ? textLib[newValue] : textLib['zh']),
			{ immediate: true }
		);
		//定义数据
		const data: Data = reactive({
			title: text.value.tip, //'提示', //t('tip'), //'提示',
			content: '您的电脑即将崩溃，请迅速撤离您的电脑即将崩溃，请迅速撤离您的电脑即将崩溃，请迅速撤离您的电脑即将崩溃，请迅速撤离您的电脑即将崩溃，请迅速撤离',
			visible: true,
			type: 'alert',
			onOk: () => {
				//
			},
			onCancel: () => {
				//
			},
		});

		//定义绑定的事件
		const handleOk = () => {
			(data as any).onOk();
			data.visible = false;
		};
		const handleCancel = () => {
			(data as any).onCancel();
			data.visible = false;
		};
		//定义键位
		const keyPrevent = (event: KeyboardEvent) => {
			event?.preventDefault();
		};
		const keyListener = (event: KeyboardEvent) => {
			event?.preventDefault();
			if (event.code === 'Escape') {
				handleCancel();
			} else if (event.code === 'Space' || event.code === 'Enter') {
				handleOk();
			}
		};
		onMounted(() => {
			document.addEventListener('keydown', keyPrevent);
			document.addEventListener('keyup', keyListener);
		});
		onBeforeUnmount(() => {
			document.removeEventListener('keydown', keyPrevent);
			document.removeEventListener('keyup', keyListener);
		});
		return { ...toRefs(data), handleOk, handleCancel, text };
	},
};
