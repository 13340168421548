export default {
    props: {
        nopadding: {
            type: Boolean,
            default: () => false,
            required: false,
        },
        nohead: {
            type: Boolean,
            default: () => false,
            required: false,
        },
        title: {
            type: String,
            default: () => '未知标题',
            required: false,
        },
    }, //['nopadding', 'nohead', 'title'],
    setup() {
        return {};
    },
};
