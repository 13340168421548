import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
//import type { Prop } from '@vue/composition-api';

import { Empty } from '@/components/UI';

import Avatar from './avatar';
import ActivityHead from './activity-head';

import { getLength, parseTime } from '@/public/methods';

//引入图标
import SvgEye from '@/svg/eye';
import SvgBook from '@/svg/book';
import SvgThumbUp from '@/svg/thumb-up';
import SvgCommit from '@/svg/commit';
import SvgArrow from '@/svg/arrow';
import SvgClock from '@/svg/clock';
import SvgLoader from '@/svg/loader';
import store from '@/store';
import { getArea, req } from '@/api';

interface TabType {
	type: number | string;
	text: string;
	count?: number;
	isActive?: boolean;
}

let timer: undefined | number = undefined;

export default defineComponent({
	props: {
		/*
        list: {
            type: Array,
            required: true,
        },
        blogCategoryList: {
            type: Object,
            required: false,
        },
        isShowTab: {
            type: Boolean,
            required: false,
            default: true,
        },
        isShowBlogToolbar: {
            type: Boolean,
            required: false,
            default: false,
        },
        */
		showType: {
			//显示方式，
			type: String,
			required: false,
			default: 'default',
			validator(value: any) {
				return ['default', 'phrase', 'blog', 'photo', 'extra'].indexOf(value) !== -1;
			},
		},
		//博客区域设置
		category: {
			type: String,
			required: false,
			default: '',
		},
		order: {
			type: String,
			required: false,
			default: '',
		},
		date: {
			type: String,
			required: false,
			default: '',
		},
		page: {
			type: Number,
			required: false,
			default: 0,
		},
		tag: {
			type: String,
			required: false,
			default: '',
		},
	},
	components: {
		Avatar,
		ActivityHead,
		SvgEye,
		SvgBook,
		SvgThumbUp,
		SvgCommit,
		SvgArrow,
		SvgClock,
		SvgLoader,
		Empty,
	},
	setup(props: any, { root }: any) {
		//const { list } = props;
		//根据showType计算显示的是导航栏还是其他
		const { showType } = props;
		const { $message, $modal } = root;
		const t = root.$t.bind(root);
		let isMounted = false;
		const isLoading: Ref<boolean> = ref(false);
		const { blogCategoryList: dataBlogCategoryList } = props;
		//const data: Ref<any> = ref([...props.list]);
		const data: Ref<any> = ref([]);
		const showData: Ref<any> = ref([]);
		const currentPage: Ref<number> = ref(0); //第几页
		const everyPageCount: Ref<number> = ref(10); //每页显示多少个
		const tab: Ref<TabType[]> = ref([
			{
				type: 1, //说说
				text: t('activity.phrase') || '说说',
				count: -1,
				isActive: false,
			},
			{
				type: 2,
				text: t('activity.article') || '文章',
				count: -1,
				isActive: false,
			},
			{
				type: 3,
				text: t('activity.photo') || '相册',
				count: -1,
				isActive: false,
			},
			{
				type: 100,
				text: t('activity.more') || '更多',
				count: -1,
				isActive: false,
			},
		]);
		watch(
			() => store.state.language,
			() => {
				timer = setTimeout(() => {
					const textObj: { [propName in number | string]: string } = {
						1: 'phrase',
						2: 'article',
						3: 'photo',
						100: 'more',
					};
					tab.value.forEach(item => (item.text = t(`activity.${textObj[item.type]}`)));
				}, 1);
			},
			{ immediate: true }
		);
		onBeforeUnmount(() => clearTimeout(timer));
		const moreKeyArray: Ref<string[]> = ref([]);
		//数据发生变化时，统计的数量也发生变化
		watch(
			() => data.value,
			value => {
				const oStatistics: any = {};
				if (Array.isArray(value)) {
					value.forEach(item => {
						if (item && typeof item.type === 'number') {
							const statisticsType = [1, 2, 3].includes(item.type) ? item.type : 100;
							if (typeof oStatistics[statisticsType] !== 'number') {
								oStatistics[statisticsType] = 0;
							}
							oStatistics[statisticsType]++;
						}
					});
				}
				tab.value = tab.value.map(item => {
					if (typeof oStatistics[item.type] === 'number' && oStatistics[item.type] > 0) {
						item.count = oStatistics[item.type];
					} else {
						item.count = -1;
					}
					//item.text += '~';
					return item;
				});
			},
			{ immediate: true }
		);
		//功能：获取文章分类的名称
		const allCategory: Ref<any> = ref([]);
		getArea('BLOG_ARTICLE_CATEGORY').then(data => (allCategory.value = data));
		const getCategoryName = (category: string | undefined): string => {
			if (category === '' || !category) {
				return '@all';
			}
			return (
				allCategory.value.find((item: any) => item.onlyid === category)?.title ||
				((): string | undefined => {
					const findRoot = allCategory.value.find((item: any) => {
						if (Array.isArray(item.children) && item.children.length > 0) {
							return item.children.find((item1: any) => item1.onlyid === category);
						}
					});
					const findDeep = findRoot?.children.find((item1: any) => item1.onlyid === category);
					if (findRoot && findRoot.title && findDeep && findDeep.title) {
						//return `${findRoot.title} / ${findDeep.title}`;
						return `${findDeep.title}`;
					} else if (findDeep && findDeep.title) {
						return `${findDeep.title}`;
					} else {
						return undefined;
					}
				})() ||
				''
			);
		};
		const articleCategoryTitle: Ref<string> = ref('');
		watch(
			() => allCategory.value,
			() => {
				const categoryName = getCategoryName(props.category);
				articleCategoryTitle.value = categoryName === '@all' ? '全部' : categoryName === '' ? '[未知分类]' : categoryName;
			}
		);

		//根据showType的不同加载不同的类型
		const errMessage: Ref<string> = ref('');
		switch (showType) {
			case 'phrase': {
				req('BLOG_PHRASE_ALL')
					.then((data: any) => {
						showData.value = data?.map((item: any) => ({
							key: 'blog_phrase' + item.onlyid,
							type: 1,
							data: item,
						})) || [{}];
					})
					.catch();
				break;
			}
			case 'blog': {
				isLoading.value = true;
				errMessage.value = '';
				//console.log('组件接收的props参数', props);
				req('BLOG_ARTICLE_ALL', {
					page: props.page || 0,
					everypage: props.everypage || 15,
					category: props.category || '',
					order: props.order || '',
					date: props.date || '',
				})
					.then((data: any) => {
						//console.log(data);
						showData.value = data?.map((item: any) => {
							return {
								key: 'blog_article_' + item.onlyid,
								type: 2,
								data: item,
							};
						}) || [{}];
					})
					.catch(error => {
						errMessage.value = '' + error;
						//$message.error(error);
					})
					.finally(() => {
						isLoading.value = false;
					});
				break;
			}
			case 'photo': {
				//
				break;
			}
			case 'extra': {
				//
				break;
			}
			default: {
				//立即监听store里面是否加载完成的变化
				watch(
					() => store?.state?.isInitAllData,
					value => {
						isLoading.value = !value;
					},
					{ immediate: true }
				);
				//立即监听store里面数据的变化
				watch(
					() => store?.state?.data?.all,
					value => {
						data.value = (value as any)?.activity?.data || [];
						currentPage.value = (value as any)?.activity?.currentPage || 0;
						everyPageCount.value = (value as any)?.activity?.everyPageCount || 10;
						//对展示区域进行调整
						showData.value = data.value;
						tab.value.forEach(item => (item.isActive = false));
					},
					{ immediate: true, deep: true }
				);
			}
		}
		onMounted(() => {
			isMounted = true;
		});
		onBeforeUnmount(() => {
			isMounted = false;
		});
		const toggleTabActive = (num: number): void => {
			const findIndex = tab.value.findIndex(item => item.type === num);
			if (findIndex >= 0) {
				tab.value[findIndex].isActive = !tab.value[findIndex].isActive;
				let timer: number | undefined = undefined;
				//根据tab数组里isActive的变化，重新渲染data
				new Promise((resolve, reject) => {
					isLoading.value = true;
					clearTimeout(timer);
					timer = setTimeout(() => {
						if (!isMounted) {
							reject('无法读取数据');
							return;
						}
						resolve('');
					}, Math.floor(Math.random() * 300));
				})
					.then(() => {
						let isActiveArr = tab.value.filter((item: any) => item.isActive).map(item1 => item1.type);
						if (isActiveArr.length === 0) {
							isActiveArr = tab.value.map(item1 => item1.type);
						}
						showData.value = data.value.filter((item: any) => {
							return isActiveArr.includes(item.type);
						});
						isLoading.value = false;
					})
					.catch((error: string) => {
						$message && $message.error ? $message.error('' + error) : window.alert(error);
						isLoading.value = false;
						tab.value[findIndex].isActive = !tab.value[findIndex].isActive;
					})
					.finally(() => {
						//
					});
			}
		};
		const phraseDoLike = (onlyid: string | number) => {
			req('SEND_LIKE', {
				onlyid,
				tribe: 'phrase',
			})
				.then((data1: any) => {
					$message.success('点赞成功，感谢您的陪伴');
					//数量+1
					const findIndex = data.value.findIndex((item: any) => item?.data?.onlyid === onlyid);
					try {
						data.value[findIndex].data.praiseCount = data1?.praiseCount;
					} catch (e) {
						//
					}
					//data.value[findIndex].data.praiseCount += 1;
				})
				.catch(errMsg => {
					$message.error('' + errMsg);
				});
		};
		return {
			showData,
			dataBlogCategoryList,
			getCategoryName,
			tab,
			toggleTabActive,
			getLength,
			parseTime,
			currentPage,
			isLoading,
			moreKeyArray,
			articleCategoryTitle,
			message: $message,
			modal: $modal,
			errMessage,
			t,
			phraseDoLike,
		};
	},
});
