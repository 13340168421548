import { Data, defineComponent, onBeforeUnmount, onMounted, reactive, toRefs } from '@vue/composition-api';
import { VueConstructor } from 'vue/types/umd';

interface Opt {
	content: string;
	icon?:
		| string
		| {
				[propName: string]: any;
		  }
		| VueConstructor<Vue>;
	time: number;
	visible: boolean;
	type: 'info' | 'bigInfo';
}

let timer: undefined | number = undefined;

export default defineComponent({
	/*
	props: {
		data: {
			required: true,
			type: Object,
			default: {},
		},
	},*/
	setup(props) {
		const data: Opt = reactive({
			content: '',
			icon: '',
			time: 2000,
			visible: false,
			type: 'info',
		});
		onMounted(() => {
			timer = setTimeout(() => {
				data.visible = false;
			}, data.time);
		});
		onBeforeUnmount(() => {
			clearTimeout(timer);
		});
		return { ...toRefs(data) };
	},
});
