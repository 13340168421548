/*
import { createI18n } from 'vue-i18n';

const i18n = createI18n({
	legacy: false,
	locale: 'zh',
	messages: {
		zh: require('@/i18n/zh.json'),
		uyg: require('@/i18n/uyg.json'),
	}, //message结构为{cn: {proProdType: {child: '婴儿'}}, hk: {proProdType: {child: '嬰兒'}}}
	fallbackLocale: 'cn',
});

export default i18n;
*/
/*
import { ref, provide, inject } from '@vue/composition-api';
const createI18n = (config: any) => ({
	locale: ref(config.locale), //响应式的数据 locale改变语言随之改变
	messages: config.messages,
	$t(key: any) {
		return getValueByPath(this.messages[this.locale.value], key);
	},
});

//支持访问a.b.c嵌套的对象结构
function getValueByPath(obj: any, path: any) {
	const paths = path.split('.');
	let res = obj;
	let prop = paths.shift();
	while (prop) {
		res = res[prop];
		prop = paths.shift();
	}
	return res;
}

const i18nSymbol = Symbol();
//用于app.vue
export function provideI18n(i18nConfig: any) {
	const i18n = createI18n(i18nConfig);
	provide(i18nSymbol, i18n);
}

//用于业务代码
export function useI18n(): any {
	const i18n = inject(i18nSymbol);
	if (!i18n) throw new Error('No i18n provided!!!');
	return i18n;
}
*/

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import i18nConfig from '@/config/i18n';
Vue.use(VueI18n);
export const i18n = new VueI18n(i18nConfig);
