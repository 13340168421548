import Vue from 'vue';
import Vuex, { Store } from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		darkMode: false,
		language: 'zh',
		isOutLine: false,
		isLoadedFirstScreen: false,
		musicPlayerControl: {
			isMusicPlayer: false, //播放器是否已打开
			otherComponentControlPause: -1, //其他组件控制播放器暂停，-1无状态，0正在播放，1已暂停，-1无状态时不改变
			isMusicPlayerReady: false,
		},
		musicPlayerCommand: '',
		isFlashPlayerActive: false,
		flashInfo: {
			title: '',
			author: '',
			authorInfo: {
				qq: '',
				mail: '',
				url: '',
				img: '',
			},
			arrow: [{ onlyid: '', title: '' }], //上一个作品，下一个作品
		},
		flashCmd: '',
		isInitAllData: false,
		data: {
			all: {},
		},
		metaInfo: {
			title: '仙娥小站', //'标题',
			keywords: '仙娥小站,个人网站,个人博客,网站建设,个人博客空间', //'关键词',
			description: '记录生活，发现美好', //'描述',
		},
		keyForRoute: '',
		articleFontSize: 'normal', //博客文章的字体大小
		isShowArticleMenu: false,
		//刷新评论/留言列表，为true是触发
		isTouchCommentList: false,
		limitImgUrl: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
		pageCopyText: '',
		isAddedAffixEvent: false,
	},
	mutations: {
		toggleDarkMode(state, boolean) {
			if (typeof boolean !== 'undefined') {
				state.darkMode = !!boolean;
			} else {
				//切换状态
				state.darkMode = !state.darkMode;
			}
			//更改节点参数以驱动CSS
			if (state.darkMode) {
				document.documentElement.setAttribute('data-theme', 'dark');
			} else {
				document.documentElement.setAttribute('data-theme', 'light');
			}
		},
		changeLanguage(state, language?: string) {
			state.language = language ? language : state.language === 'zh' ? 'uyg' : 'zh'; //;
			document.documentElement.setAttribute('lang', state.language + '-CN');
			document.documentElement.setAttribute('dir', state.language === 'uyg' ? 'rtl' : 'ltr');
			//向服务器发送指令，将服务端的语言也更改
			import('@/i18n')
				.then(({ i18n }: any) => {
					i18n.locale = state.language;
				})
				.catch(() => {
					/** */
				});
		},
		updateOutLineState(state) {
			if (navigator.onLine === true) {
				state.isOutLine = false;
			} else if (navigator.onLine === false) {
				state.isOutLine = true;
			}
		},
		loadedFirstScreen(state) {
			state.isLoadedFirstScreen = true;
		},
		toggleMusicPlayer(state) {
			state.musicPlayerControl.isMusicPlayer = !state.musicPlayerControl.isMusicPlayer;
			state.musicPlayerControl.otherComponentControlPause = 0;
		},
		musicPlayerPauseOrPlay(state, value: boolean) {
			//true:暂停,false播放
			state.musicPlayerControl.otherComponentControlPause = value ? -1 : 0;
		},
		musicPlayerControl(state, command) {
			if (command === '') {
				state.musicPlayerControl.isMusicPlayerReady = false;
				state.musicPlayerCommand = '';
				return;
			} //
			if (!state.musicPlayerControl.isMusicPlayer) {
				state.musicPlayerControl.isMusicPlayer = !state.musicPlayerControl.isMusicPlayer;
				state.musicPlayerControl.otherComponentControlPause = 0;
			}
			new Promise((resolve, reject) => {
				//console.log('123');
				if (state.musicPlayerControl.isMusicPlayerReady) {
					resolve('');
				} else {
					//setTimeout(resolve, 1000);
					resolve('');
				}
			})
				.then(() => {
					if (typeof command !== 'string') {
						if (typeof command === 'object') {
							command = JSON.stringify(command);
						} else {
							command = '';
						}
					}
					state.musicPlayerCommand = command;
				})
				.catch(() => {
					//
				});
		},
		activeFlash(state) {
			state.isFlashPlayerActive = true;
			//console.log('调用了全局激活', state);
		},
		registerFlashInfo(state, data) {
			if (typeof data !== 'object' && data === -1) {
				//清空信息表
				state.flashInfo = {
					title: '',
					author: '',
					authorInfo: {
						qq: '',
						mail: '',
						url: '',
						img: '',
					},
					arrow: [],
				};
				return;
			}
			state.flashInfo.title = data?.title || '';
			state.flashInfo.author = data?.author || '';
			if (typeof data?.authorUrl !== 'undefined') {
				const { authorUrl } = data;
				let authorInfo = {};
				let successToJson = true;
				try {
					authorInfo = JSON.parse(authorUrl);
				} catch (e) {
					successToJson = false;
				}
				if (successToJson) {
					state.flashInfo.authorInfo = Object.assign(state.flashInfo.authorInfo, authorInfo);
				} else {
					//检测是邮箱还是网址
					const authorInfoStr = ('' + authorUrl).trim();
					const isMail = /^([a-zA-Z]|[0-9])(\w|-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(authorInfoStr);
					if (isMail) {
						state.flashInfo.authorInfo.mail = authorInfoStr;
						if (authorInfoStr.includes('@qq.com')) {
							state.flashInfo.authorInfo.qq = authorInfoStr.split('@')[0];
						}
					} else {
						state.flashInfo.authorInfo.url = authorInfoStr;
					}
				}
				//console.log('vuex状态里的', state.flashInfo);
			}
			if (typeof data?.arrow !== 'undefined' && Array.isArray(data?.arrow)) {
				state.flashInfo.arrow = data.arrow;
			}
			state.flashInfo = Object.assign(state.flashInfo, {});
		},
		flashPassive(state, value: string) {
			//被动命令
			if (value.includes('@')) {
				return;
			}
			state.flashCmd = value + '@' + Math.floor(Math.random() * 16777215).toString(16);
		},
		initAllData(state, data) {
			if (state.isInitAllData) {
				console.warn('禁止重复获取AllData数据');
				return;
			}
			//获取综合数据
			state.data.all = data;
			//console.log('获取了综合数据', state.data);
			state.isInitAllData = true;
			//console.log('调用了store里面的', data);
		},
		changeMetaInfo(state, metaInfo) {
			state.metaInfo = Object.assign(state.metaInfo, metaInfo);
		},
		changeRouteKey(state) {
			state.keyForRoute = Math.floor(Math.random() * 4294967295).toString(16);
		},
		changeArticleFontSize(state, value: string) {
			value = ('' + value).toLowerCase().trim();
			if (!['large', 'small'].includes(value)) {
				value = 'normal';
			}
			state.articleFontSize = value;
			state.isShowArticleMenu = false;
		},
		toggleArticleMenu(state, value: boolean | undefined) {
			state.isShowArticleMenu = !!value;
		},
		touchCommentList(state, value: boolean | undefined) {
			state.isTouchCommentList = !!value;
		},
		onPageCopy(state, isSuccess) {
			state.pageCopyText = (isSuccess ? '+' : '-') + Math.random();
		},
		handleAffixEventCanAdd(state, value: boolean) {
			state.isAddedAffixEvent = value;
		},
		otherComponentControlMusicPlayerPause(state, value: boolean) {
			//console.log('接收到Flash组件的指令');
			//状态，是否暂停
			if (state.musicPlayerControl.otherComponentControlPause === -1) {
				return;
			}
			state.musicPlayerControl.otherComponentControlPause = value ? 1 : 0;
			//console.log(
			//    '开始变值 ',
			//    value,
			//    state.musicPlayerControl.otherComponentControlPause
			//);
		},
	},
	actions: {},
	modules: {},
});

