import { reactive, toRefs, ref, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import { Btn } from '@/components/UI';

import SplitCode from './split-code';
import store from '@/store';

export default {
	props: {
		code: {
			//type: Number || String,
			default: 404,
			required: false,
		},
	},
	components: { Btn, SplitCode },
	setup(props: any, { root }: any) {
		const { code } = props;
		const { $router, $route, $store } = root;
		const t = root.$t.bind(root);
		//定义元信息
		$store.commit('changeMetaInfo', {
			title: `${'' + code} - ${$route.meta.metaInfo.title || ''}`,
		});
		//生成惟一key
		const getOnlyKey = (): string => {
			return Number('' + Math.floor(Math.random() * 9999) + Math.floor(new Date().getTime() / 1000)).toString(32);
		};
		const key: Ref<string> = ref(getOnlyKey());
		const data = reactive({
			msg:
				store.state.language !== 'zh'
					? {
							0: 'نامەلۇم خاتالىق',
							400: 'تورنىڭ ئۇلىنىشىنىڭ نورمال ياكى ئەمەسلىكىنى تەكشۈرۈڭ',
							403: 'مۇلازىم بۇ قېتىمقى تەلەپنى رەت قىلدى',
							404: 'بەت يۈزى مەۋجۇت ئەمەس',
							500: 'مۇلازىمەتچىدە خاتالىق كۆرۈلدى',
							502: 'خاتا تەلەپ',
							503: 'مۇلازىمېتىر نورمىدىن ئاشۇرۇپ يۈك بېسىپ يۈ',
					  }
					: {
							0: '未知错误',
							400: '您似乎断开了与互联网的连接',
							403: '服务器拒绝执行此请求',
							404: ['您访问的页面大概变成蝴蝶飞走了'],
							500: '服务器内部错误',
							502: '错误的请求',
							503: '服务器超负载运行',
					  },
		});
		//定义按钮的功能
		const handle = (method: string): void => {
			switch (method) {
				case 'refreshComponent':
					//alert('刷新组件');
					root.$forceUpdate();
					//console.log($route);
					key.value = getOnlyKey();
					$router.replace({
						path: $route.path,
						query: {
							...$route.query,
							_hash: getOnlyKey(),
						},
					});
					break;
				case 'refreshPage':
					document.location.reload();
					break;
				case 'goHome': {
					const query = $route?.path === '/' ? (typeof $route?.query?._hash === 'undefined' ? { _hash: getOnlyKey() } : {}) : {};
					$router.push({
						path: '/',
						query,
					});
					break;
				}
				case 'goBoard':
					$router.push({
						path: '/about/guestboard',
						query: {
							_hash: getOnlyKey(),
						},
					});
					break;
				default:
			}
			//
		};
		return { ...toRefs(data), key, handle, t };
	},
};
