import { ref, onMounted, watch } from '@vue/composition-api'; //,provide, inject, computed,
import type { Ref } from '@vue/composition-api';

//多语言扩展
//import { provideI18n } from '@/i18n';
//import i18nConfig from '@/config/i18n';

//引入自定义的文件
import compatPolyfill from './compat-polyfill';
import commitEvents from './commit-events';
//import debugForProduction from './debug-for-production';
import consoleRewrite from './console-rewrite';
import consoleLock from './console-lock';
import consoleCopyright from './console-copyright';
import baiduStatistics from './baidu-statistics';

//引入使用控制器的组件
import PreviewController from './ControllerComponents/preview-controller';

//import Loading from '@/components/Loading';
import BackTop from '@/components/BackTop';

import Card from '@/components/Card';
import Logo from '@/components/Logo';
import Banner from '@/components/Banner';
//import Profile from '@/components/Article/Profile';

import Error from '@/components/Error';

import PageHeader from '@/components/PageHeader';
import PageFooter from '@/components/PageFooter';
import PageContent from '@/components/PageContent';

//import pub from '@/public/pub';
import { app as appConfig } from '@/config';
const MusicPlayer = () => import(/*webpackChunkName:'chunk-music_player'*/ '@/components/MusicPlayer');

export default {
	name: 'App',
	components: {
		//Loading,
		BackTop,
		PageHeader,
		PageContent,
		PageFooter,
		MusicPlayer,
		Card,
		Logo,
		Banner,
		//Profile,
		Error,
		PreviewController,
	},
	setup(props: any, { root }: any) {
		//provideI18n(i18nConfig); //注册多语言扩展
		onMounted(() => {
			compatPolyfill(); //自定义兼容性函数
			commitEvents(); //自定义事件
			//debugForProduction();//针对生产环境中的BUG提示
			consoleRewrite(); //重写控制台规则
			consoleLock(); //控制台上锁
			consoleCopyright(); //控制台信息输出
			//检测来源$_GET['from']或$_GET['rel']，检测到真实URL，从数据库中的友链查找，给出欢迎的消息提示
			//博客页面中加载TTS语音，可以动态阅读
			//百度统计
			baiduStatistics();
		});
		//自动检测是否断网
		const outLine: Ref<boolean> = ref(false);
		const { $store, $message } = root;
		watch(
			() => $store.state.isOutLine,
			isOutLine => (outLine.value = isOutLine)
		);

		if (appConfig.isImmediateOnLine) {
			//实时监测断网状态
			setInterval(() => {
				$store.commit('updateOutLineState');
			}, 100);
		}
		//音乐播放器的功能控制
		const isMusicPlayer: Ref<boolean> = ref(false);
		watch(
			() => $store.state.musicPlayerControl.isMusicPlayer,
			toggle => (isMusicPlayer.value = toggle)
		);
		//页面复制时需要监听的内容
		watch(
			() => $store.state.pageCopyText,
			msg => {
				msg.substring(0, 1) === '+' ? $message.info('已复制，转载请注明出处') : $message.error('复制失败');
			}
		);
		return { outLine, isMusicPlayer };
	},
};
