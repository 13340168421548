var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('fetch-data',{attrs:{"area":"PHOTO_PHOTO_HOT","isSkeleton":false,"isCache":true},on:{"touchData":_vm.touchData}}),_c('ul',{staticStyle:{"display":"flex","flex-wrap":"wrap","justify-content":"space-around"}},_vm._l((_vm.photoHotData /*[
				'http://127.0.0.1/upload/photo/4ae451e9hc913c3a582ed.jpg',
				'http://127.0.0.1/app.jpg',
				'http://127.0.0.1/upload/photo/4ae451e9hbee0f04aaa0e.jpg',
				'http://127.0.0.1/upload/photo/4ae451e9hc913c3a582ed.jpg',
				'http://127.0.0.1/upload/photo/4ae451e9hc0121ed23f67.jpg',
				'http://127.0.0.1/upload/photo/4ae451e9hbee0f04aaa0e.jpg',
			]*/),function(item,index){return _c('li',{key:index,staticStyle:{"width":"100px"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.imgUrl),expression:"item.imgUrl"}],staticStyle:{"object-fit":"cover","width":"100%","border-radius":"5px","cursor":"zoom-in"},attrs:{"preview":_vm.imgGroupKey,"src":item.imgUrl,"large":item.imgUrl,"alt":item.title,"preview-text":item.title},on:{"dragstart":function($event){$event.preventDefault();}}})])}),0)],1)}
var staticRenderFns = []

export { render, staticRenderFns }