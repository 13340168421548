import { ref, onMounted, onBeforeUnmount, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import pub from '@/public/pub';

export default {
    props: {
        done: {
            type: Boolean,
            required: false,
        },
    },
    setup(props: any, { root }: any) {
        const { $store } = root;
        const isLoading: Ref<boolean> = ref(true);
        const progress: Ref<number> = ref(0);
        const isNetworkDenied: Ref<boolean> = ref(navigator.onLine === false);
        //!!pub.get('networkDenied') ||
        let timer: number | undefined = undefined;
        const shouldPlus = (progress: number): number => {
            let res = 0;
            res = (1 - progress) * 0.04;
            //res = res < 0.01 ? 0.01 : res;
            res = progress > 0.8 ? 0.001 : res;
            res = progress > 0.85 ? 0.0001 : res;
            res = progress > 0.9 ? 0.00001 : res;
            res = progress > 0.95 ? 0 : res;
            return res;
        };
        onMounted(() => {
            clearInterval(timer);
            //设置断网时进度条无效
            timer = setInterval(() => {
                $store?.commit('updateOutLineState');
                if ($store?.state?.isOutLine) {
                    isNetworkDenied.value = true;
                    clearInterval(timer);
                    return;
                }
                progress.value += shouldPlus(progress.value);
                //console.log('加载中');
            }, 50);
        });
        onBeforeUnmount(() => {
            clearInterval(timer);
        });
        //console.log(props, '是否完成', props.done);
        watch(
            () => props.done,
            done => {
                if (done) {
                    clearInterval(timer);
                    progress.value = 1;
                    //alert('久等了，加载完毕！');
                }
            }
        );

        //const click = () => {
        //    progress.value += 0.1;
        //};

        return { isLoading, progress, isNetworkDenied };
    },
};
