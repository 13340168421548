//加载状态管理器
//import store from '@/store';

//加载网站的API
//import { getApiUrl } from './api';

//加载请求方法
import pub from '@/public/pub';
import { requireMethod } from './methods';

//导出请求函数
export const req = requireMethod;

//优先使用缓存读取，此方法无法携带参数
export const getArea = (area: string) => {
    return new Promise((resolve, reject) => {
        const data = pub.get('__API__CACHE__' + area);
        if (data) {
            resolve(data);
        } else {
            req(area)
                .then(data => {
                    pub.set('__API__CACHE__' + area, data);
                    resolve(data);
                })
                .catch(error => reject(error));
        }
    });
    //pub.set('_API_'+area,);
};

//console.log(store);
//store.commit()

//旧版
export const ERROR_REPORTING = '/api/report';
export const ALL = '/api/getcount/all';

//export const MUSIC_LIST = '/api/';

//博客部分
export const BLOG_ALL = '/api/article/allwithdesc';
