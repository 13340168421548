import { ref, reactive, onMounted, onBeforeUnmount, watch, toRefs, nextTick } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import QRCode from 'qrcode';
import { Time } from 'view-design';
import { req } from '@/api';

//import VueQr from 'vue-qr';
//import { config } from '@vue/test-utils';
//const VueQr = import('vue-qr');

interface ResponseData {
	visitorInfo: {
		visitor: number;
		peopleOnline: number;
	};
}

export default {
	name: 'PageFooter',
	//components: { VueQr },
	setup(props: any, { root }: any) {
		/**
            //二维码内容
            text:'http://www.itxst.com',
            //logo图标 注意必须图片和网页在同一个域名下面
            logoSrc:'https://www.itxst.com/package/vue-qr/logo.png',
            //autoColor未false,二维码数据点的颜色
            colorDark:'#007bff',
            //二维码数据点的速率比，默认未0.35
            dotScale:0.3,
            //背景图的速率比
            bgcale:0.8,
            //二维码背景图  注意必须图片和网页在同一个域名下面
            bgSrc:'https://www.itxst.com/package/vue-qr/bg0.png',
            //二维码数据点的颜色是否自动生成颜色
            autoColor:true,
            //二值化
            binarize:false,
            //二值化值
            binarizeThreshold:130
         * 
         * 
         * 
         */
		const { $router, $store } = root;

		const t = root.$t.bind(root);
		(...arg: any) => {
			root.$t.bind(root)(`footerText.${arg[0]}`, arg[1]);
		};

		//二维码控制器
		/**
		 * 考虑兼容IE，没有使用VueQr，这里我也没办法，就这么用着吧，以后再说吧
		 */
		const url: Ref<string> = ref('' + document.location.href);
		const qqGroupLink: Ref<string> = ref('https://jq.qq.com/?_wv=1027&k=52qSx61O');
		//ref('https://jq.qq.com/?_wv=1027&k=JwTEQGbp');
		//ref('https://qm.qq.com/cgi-bin/qm/qr?k=TTYwmYwOhIzdx5T9N3cnbqeZ4a0BbJcJ&authKey=xit7BLTwoROoofujthXV8t+jXZ+jh7chBYv+GDohDFU/qF39bd5tL0ek7zRgRftT&noverify=0');
		$router.afterEach(() => {
			url.value = '' + document.location.href;
		});
		const urlDOM: Ref<any> = ref(null);
		const qqGroupLinkDOM: Ref<any> = ref(null);
		let isQrDarkMode = !!$store.state.darkMode;
		function twiceQRCode(DOM: Document, text: string) {
			(QRCode as any).toCanvas(DOM, text, {
				errorCorrectionLevel: text.length > 30 ? 'low' : 'high',
				margin: 0,
				width: 100,
				height: 100,
				colorDark: '#000000ff',
				colorLight: '#ffffff00',
			});
		}
		const codeFetch = () => {
			twiceQRCode(urlDOM.value, url.value);
			twiceQRCode(qqGroupLinkDOM.value, qqGroupLink.value);
		};
		onMounted(() => {
			codeFetch();
		});
		//监听URL变化时，自动重新生成当前二维码
		watch(
			() => url.value,
			url => {
				twiceQRCode(urlDOM.value, url);
			}
		);
		//监听深色模式浅色模式变化时
		watch(
			() => $store.state.darkMode,
			(value: any) => {
				isQrDarkMode = !!value;
				codeFetch();
			}
		);

		//记录页面加载耗时
		const pageLoadTake: Ref<number> = ref(-1); //Math.random() * 1000
		let pageLoadStartTime = -1;
		let pageLoadEndTime = -1;
		//第一次整个页面的加载
		if (!$store.state.isLoadedFirstScreen && typeof (window as any).__sheanehConfig__ === 'object' && typeof (window as any).__sheanehConfig__.get === 'function') {
			if (typeof (window as any).__sheanehConfig__.get('pageStartTime') === 'number') {
				pageLoadTake.value = new Date().getTime() - (window as any).__sheanehConfig__.get('pageStartTime');
				$store.commit('loadedFirstScreen');
			}
		}
		//以后每次加载组件
		$router.beforeEach((to: any, from: any, next: any) => {
			pageLoadStartTime = new Date().getTime();
			next();
		});
		$router.afterEach(() => {
			if (pageLoadStartTime >= 0) {
				pageLoadEndTime = new Date().getTime();
				pageLoadTake.value = pageLoadEndTime - pageLoadStartTime;
			}
		});

		//获取当前多少访客，当前正在访问人数
		const data = reactive({
			visitor: -1, //当前多少访客
			peopleOnline: -1,
			webCreateTime: new Date(2008, 6, 12, 21, 0, 0), //网站创立时间
			webOnlineTime: new Date(2021, 3, 28, 21, 0, 0), //网站上线时间
		});
		const getYear = (obj: Date): number => {
			return obj.getFullYear();
		};
		let timer: number | undefined = undefined;
		onMounted(() => {
			const frameReq = (isRefreshVisitor = false) => {
				req('COMMUNICATION', {}, 'POST')
					.then((responseData: any) => {
						responseData as ResponseData;
						//console.log('成功', data);
						if (isRefreshVisitor) {
							data.visitor = responseData.visitorInfo.visitor;
						}
						data.peopleOnline = responseData.visitorInfo.peopleOnline;
					})
					.catch(() => {
						//console.log('失败' + err);
					});
			};
			frameReq(true);
			timer = setInterval(() => {
				//向后端发送请求，表明正在访问
				frameReq();
			}, 10000);
		});
		onBeforeUnmount(() => {
			clearInterval(timer);
		});

		//路由跳转前将此块隐藏，跳转后恢复正常
		const isShowInRouter: Ref<boolean> = ref(true);
		let timerInRouter: number | undefined = undefined;
		$router.beforeEach((to: any, from: any, next: any) => {
			isShowInRouter.value = false;
			//console.log('将要', to, '准备', from);
			//console.log();
			clearTimeout(timerInRouter);
			next();
		});
		$router.afterEach(() => {
			clearTimeout(timerInRouter);
			timerInRouter = setTimeout(() => {
				isShowInRouter.value = true;
			}, 500);
		});
		return {
			url,
			qqGroupLink,
			urlDOM,
			qqGroupLinkDOM,
			pageLoadTake,
			...toRefs(data),
			getYear,
			isShowInRouter,
			t,
		};
	},
};
