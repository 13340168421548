
























































import { onMounted, onUpdated, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

export default {
    props: {
        width: [Number, String],
        height: [Number, String],
    },
    setup(props: any) {
        const { width, height } = props;
        const widthValue: Ref<any> = ref(0);
        const heightValue: Ref<any> = ref(0);
        const valueChange = () => {
            widthValue.value = width;
            heightValue.value = height;
        };
        onMounted(valueChange);
        onUpdated(valueChange);
        return { widthValue, heightValue };
    },
};
