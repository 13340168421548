import AffixComponent from './Affix';
import BtnComponent from './Btn';
import InpComponent from './Inp';
import CardComponent from './Card';
import MessageComponent from './Message';
import ModalComponent from './Modal';
import EmptyComponent from './Empty';
import TabComponent from './Tab';
import TipComponent from './Tip';
import SkeletonComponent from './Skeleton';

export const Affix = AffixComponent;
export const Btn = BtnComponent;
export const Inp = InpComponent;
export const Card = CardComponent;
export const Message = MessageComponent;
export const Modal = ModalComponent;
export const Empty = EmptyComponent;
export const Tab = TabComponent;
export const Tip = TipComponent;
export const Skeleton = SkeletonComponent;

export default {
	Affix,
	Btn,
	Inp,
	Card,
	Message,
	Modal,
	Empty,
	Tab,
	Tip,
	Skeleton,
};
