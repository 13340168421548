import { defineComponent, ref, nextTick, onMounted } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import store from '@/store';

import FetchData from '@/components/FetchData';
import { generateOnlyid } from '@/public/methods';

interface PhotoHotData {
	onlyid: string | number;
	title: string;
	description: string;
	imgUrl: string;
	time?: number;
}

export default defineComponent({
	components: { FetchData },
	setup(props, { root }: any) {
		const { $previewRefresh, $preview } = root;
		const photoHotData: Ref<PhotoHotData[]> = ref([
			{
				onlyid: 'aaaaaaa',
				title: '标题占位符',
				description: '简介占位符',
				imgUrl: store.state.limitImgUrl || '',
			},
			{
				onlyid: 'bbbbbb',
				title: '标题占位符12345',
				description: '简介占位符',
				imgUrl: store.state.limitImgUrl || '',
			},
			{
				onlyid: 'ccccccc',
				title: '标题占位符123',
				description: '简介占位符1111',
				imgUrl: store.state.limitImgUrl || '',
			},
		]);

		const touchData = (data: PhotoHotData[]) => {
			photoHotData.value = data;
			nextTick(() => {
				$previewRefresh();
			});
		};
		onMounted(() => {
			$preview.on('close', () => {
				//console.log('关闭回调');
			});
		});
		const imgGroupKey = generateOnlyid();

		return { photoHotData, touchData, imgGroupKey };
	},
});
