const baiduStatistics = () => {
    //alert('百度测试');
    (window as any)._hmt = (window as any)._hmt || [];
    (function () {
        const hm = document.createElement('script');
        hm.setAttribute(
            'data-note',
            '此处引用百度访客统计的代码，如果需要更改ID，请在后台管理系统中设置'
        );
        hm.src = 'https://hm.baidu.com/hm.js?9925121ba26ef7054dfd0dac61c90483';
        const s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(hm, s);
    })();
};

export default baiduStatistics;
