import { computed, onBeforeUnmount, onMounted, reactive, ref, watch } from '@vue/composition-api';
import type { Data, Ref } from '@vue/composition-api';

import store from '@/store';

import Card from '@/components/Card';

export default {
	components: { Card },
	setup(props: any, { root }: any) {
		const { $route, $routes } = root;
		const t = root.$t.bind(root);
		//显示面包屑
		const isShowBread = computed({
			get: () => $route?.path !== '/',
			set: () => false,
		});
		//面包屑功能实现
		const data: Data = reactive({
			crumb: [
				{
					text: '首页',
					path: '/',
				},
			],
		});
		onMounted(() => {
			data.crumb = (() => {
				const res = [
					{
						text: '首页',
						path: '/',
					},
				];
				$route.matched.forEach((item: any) => {
					//console.log(item?.meta?.breadName, item.path);
					if (item.path !== '' && item?.meta?.breadName) {
						res.push({
							text: item.meta.breadName,
							path: item.path,
						});
					}
				});
				return res;
			})();
		});
		//博文字体大小及文章目录的控制功能实现
		const isShowTextControl = computed({
			get: () => $route?.path?.includes('/blog/article/detail/'),
			set: () => true,
		});
		const textSize: Ref<string> = ref('normal');
		watch(
			() => store.state.articleFontSize,
			value => (textSize.value = value),
			{ immediate: true }
		);
		const changeTextSize = (value: string): void => {
			store.commit('changeArticleFontSize', value);
			isArticleMenuOpen.value = false;
		};
		const isArticleMenuShow: Ref<boolean> = ref(true); //根据当前文章的结构，如果结构复杂就显示目录按钮
		const isArticleMenuOpen: Ref<boolean> = ref(false);
		const toggleArticleMenu = (toggle: boolean | undefined): void => {
			toggle = typeof toggle === 'boolean' ? toggle : !isArticleMenuOpen.value;
			//更改本组件内状态
			isArticleMenuOpen.value = toggle;
			//更改其他组件内状态
			store.commit('toggleArticleMenu', toggle);
		};
		onBeforeUnmount(() => {
			toggleArticleMenu(false);
		});
		return {
			isShowBread,
			isShowTextControl,
			textSize,
			changeTextSize,
			isArticleMenuShow,
			isArticleMenuOpen,
			toggleArticleMenu,
			data,
			t,
		};
	},
};
