import Vue from 'vue';
import Modal from './Modal.vue';

const modalBox = Vue.extend(Modal as any);

(Modal as any).install = function (options: any, type: any) {
	//sconsole.log(options);
	if (typeof options === 'function') {
		//alert('是函数');
		options = options();
	}
	if (typeof options === 'undefined' || !options) {
		options = {
			content: '',
		};
	} else if (typeof options === 'string' || typeof options === 'number') {
		options = {
			content: '' + options,
		};
		if (type !== undefined && options !== null) {
			options.type = type;
		}
	}
	const instance = new modalBox({
		data: options,
	}).$mount();
	const modalContainer =
		document.querySelector('div[data-shui-modal]') ||
		(() => {
			const modalContainer = document.createElement('div');
			modalContainer.setAttribute('data-shui-modal', '');
			modalContainer.setAttribute('class', 'shui-modal-package');
			document.body.appendChild(modalContainer);
			return modalContainer;
		})();
	modalContainer.innerHTML = '';
	modalContainer.appendChild(instance.$el);
	Vue.nextTick(() => {
		instance.visible = true;
	});
};

if (!Vue.prototype.$modal) {
	Vue.prototype.$modal = (Modal as any).install;
}
export default Modal;
