var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-node-main",attrs:{"data-page-node-main":""}},[(_vm.isShowBread)?_c('card',{attrs:{"nohead":true,"title":"当前位置"}},[_c('div',{staticClass:"bread-area"},[_c('div',{staticClass:"bread-crumb"},[_c('div',{staticClass:"bread-crumb-head"},[_c('span',{staticClass:"inline-key"},[_vm._v(_vm._s(_vm.t('partBread.local')))])]),_vm._l((_vm.data.crumb),function(item,index){return _c('div',{key:item.path,staticClass:"bread-crumb-each"},[_c('router-link',{attrs:{"to":item.path.indexOf(':') !== -1 ? '' : item.path}},[_vm._v(_vm._s(item.text))]),(index < _vm.data.crumb.length - 1)?_c('span',{staticClass:"bread-crumb-split"}):_vm._e()],1)})],2),(_vm.isShowTextControl)?_c('div',{staticClass:"bread-text-controll"},[_c('div',_vm._l(([
							{
								text: '大',
								key: 'large',
							},
							{
								text: '中',
								key: 'normal',
							},
							{
								text: '小',
								key: 'small',
							} ]),function(item){return _c('a',{key:item.key,staticClass:"bread-text-controll-span",class:{
							'bread-text-active': _vm.textSize === item.key,
						},attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.changeTextSize(item.key)}}},[_c('span',[_vm._v(_vm._s(_vm.t(("partBreadCrumb." + (item.key))) || item.text))])])}),0),(_vm.isArticleMenuShow)?_c('div',[_c('a',{class:{ 'bread-text-active': _vm.isArticleMenuOpen },attrs:{"href":"javascript:;"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleArticleMenu.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.t('partBreadCrumb.menu') || '目录'))])]):_vm._e()]):_vm._e()])]):_vm._e(),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }