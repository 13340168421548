import { computed, onMounted, reactive, toRefs } from '@vue/composition-api';
import type { Data } from '@vue/composition-api';

import SvgInfo from '@/svg/info';
import SvgSuccess from '@/svg/success';
import SvgWarn from '@/svg/warn';
import SvgError from '@/svg/error';

export default {
    components: {
        SvgInfo,
        SvgSuccess,
        SvgWarn,
        SvgError,
    },
    props: {
        //
    },
    setup(props: any, context: any) {
        const data: Data = reactive({
            content: '',
            time: 3000,
            visible: false,
            type: 'info', //success,warning,error
            hasClose: false,
        });
        if (
            !['success', 'warning', 'error', 'info'].find(
                item => data.type === item
            )
        ) {
            data.type = 'info';
        }
        const getsvgComponent = ($type: any) => {
            switch ($type) {
                case 'success':
                    return 'svg-success';
                case 'warning':
                    return 'svg-warn';
                case 'error':
                    return 'svg-error';
                default:
                    return 'svg-info';
            }
        };

        onMounted(() => {
            setTimeout(() => {
                data.visible = false;
            }, (data as any).time);
        });
        return { ...toRefs(data), getsvgComponent };
    },
};
