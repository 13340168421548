


























import { ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

export default {
	props: {
		code: {
			type: Number,
			default: 404,
			required: false,
		},
	},

	setup(props: any) {
		const code = '' + (props.code || 404);
		const codeArr: Ref<string[]> = ref(code.split(''));
		return { codeArr };
	},
};
