import { defineComponent, onBeforeUnmount, onMounted, ref, getCurrentInstance } from '@vue/composition-api';
import type { ComponentInternalInstance } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';
import store from '@/store';

export default defineComponent({
	name: 'PageNodeSub',
	setup() {
		const { proxy } = getCurrentInstance() as ComponentInternalInstance;
		const { $route } = proxy;
		const t = proxy.$t.bind(proxy);
		const affixMode: Ref<0 | 1 | 2> = ref(0);
		const domForAffix: Ref<HTMLElement | null> = ref(null);
		//功能：实现固钉
		const eventFn = (event: Event) => {
			const affixTopPosition = 55;
			const affixBottomPosition = 25 + 155;
			const affixBottomFixedPosition = store.state.musicPlayerControl.isMusicPlayer ? 66 : 0; //根据播放器状态判断
			const affixScrollTopPosition = 330;
			function reset() {
				affixMode.value = 0;
				(domForAffix.value as HTMLElement).style.top = null;
				(domForAffix.value as HTMLElement).style.bottom = null;
				(domForAffix.value as HTMLElement).style.width = null;
			}
			//分辨率小时不适用
			if (window.screen.availWidth < 1367 || document.documentElement.offsetWidth < 1367) {
				reset();
				return;
			}
			//IE浏览器下，若本sub高度大于主main高度，则不适用
			if (/Trident/.test(navigator.userAgent)) {
				const subElementHeight = (() => {
					const subElement = domForAffix.value;
					if (subElement && typeof subElement.getBoundingClientRect === 'function') {
						return subElement?.getBoundingClientRect()?.height || -1;
					} else {
						return -1;
					}
				})();
				const mainElementHeight = (() => {
					const mainElement = document.querySelector('[data-page-node-main]');
					if (mainElement) {
						const childNodes = mainElement.childNodes;
						let totalHeight = 0;
						childNodes.forEach(itemElement => {
							if (itemElement && typeof itemElement === 'object' && typeof (itemElement as HTMLElement).getBoundingClientRect === 'function') {
								totalHeight += (itemElement as HTMLElement)?.getBoundingClientRect()?.height || 0;
							}
						});
						return totalHeight || -1;
					} else {
						return -1;
					}
				})();
				//console.log('sub高' + subElementHeight + ',main高' + mainElementHeight);
				if (subElementHeight > mainElementHeight || subElementHeight === -1 || mainElementHeight === -1) {
					reset();
					return;
				}
			}
			if (domForAffix.value !== null && typeof domForAffix.value === 'object') {
				//只有在不为null时才监听事件
				const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
				const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
				const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
				//如果没有滚动
				if (scrollTop < affixScrollTopPosition - affixTopPosition) {
					//
					reset();
					return;
				} else {
					//console.log(domForAffix.value.offsetHeight);
					//获取里面所有元素的高度
					let isChange = false;
					const { childNodes: nodes } = domForAffix.value;
					let allNodesHeight = 0;
					allNodesHeight = (() => {
						const DOM = domForAffix.value as HTMLElement;
						const h = (DOM.lastChild as HTMLElement).offsetTop + (DOM.lastChild as HTMLElement).offsetHeight;
						if (h) {
							return h;
						} else {
							return 0;
						}
					})();

					if (allNodesHeight <= 0) {
						nodes.forEach(item => {
							allNodesHeight += (item as any)?.offsetHeight || 0;
						});
					}
					//allNodesHeight += affixTopPosition;
					//console.log('所有的元素加起来之和', allNodesHeight);
					/*
                    console.log(
                        allNodesHeight,
                        window.screen.availHeight -
                            affixTopPosition -
                            affixScrollTopPosition
                    );*/
					//当边侧元素的高度比主侧元素的高度大时,不响应
					/*
                    console.log(
                        allNodesHeight,
                        affixScrollTopPosition +
                            allNodesHeight +
                            affixBottomPosition +
                            affixBottomFixedPosition +
                            50,
                        scrollHeight
                    );
                    */
					if (affixScrollTopPosition + allNodesHeight + affixBottomPosition + affixBottomFixedPosition + 50 >= scrollHeight) {
						reset();
						return;
					}
					//当元素小于屏幕可见范围时
					if (
						allNodesHeight + affixTopPosition < scrollHeight - affixTopPosition - affixScrollTopPosition &&
						allNodesHeight < (document.documentElement.clientHeight || document.body.clientHeight)
					) {
						//如果滚动了但DOM的高度小于页面的高度
						//console.log('模式一');
						affixMode.value = 1;
						(domForAffix.value as HTMLElement).style.top = affixTopPosition + 'px';
						(domForAffix.value as HTMLElement).style.bottom = null;
						isChange = true;
					} else {
						//或者滚动了但DOM的高度大于页面的高度
					}
					//当滚动条到底时
					if (
						/*
                        scrollTop >
                        document.documentElement.scrollHeight -
                            affixScrollTopPosition -
                            allNodesHeight
                            */
						(!isChange && scrollTop > affixScrollTopPosition + affixBottomFixedPosition + allNodesHeight - (document.documentElement.clientHeight || document.body.clientHeight)) ||
						scrollHeight - scrollTop < allNodesHeight + affixBottomPosition + affixBottomFixedPosition + affixTopPosition
						//affixBottomFixedPosition -                            affixBottomPosition +
						//affixBottomFixedPosition -
					) {
						affixMode.value = 2;
						(domForAffix.value as HTMLElement).style.top = null;
						/*
                        console.log(
                            scrollHeight - clientHeight - scrollTop,
                            affixBottomPosition
                        );
                        */
						if (scrollHeight - clientHeight - scrollTop < affixBottomPosition) {
							(domForAffix.value as HTMLElement).style.bottom = affixBottomPosition - (scrollHeight - clientHeight - scrollTop) + affixBottomFixedPosition + 'px';
						} else {
							(domForAffix.value as HTMLElement).style.bottom = affixBottomFixedPosition + 'px';
						}
						//
					} else {
						if (!isChange) {
							reset();
							return;
						}
					}
					(domForAffix.value as HTMLElement).style.width = (domForAffix.value as HTMLElement).parentNode.offsetWidth + 'px';
				}
			}
		};
		onMounted(() => {
			//console.log('获取DOM', domForAffix.value);
			if (!store.state.isAddedAffixEvent) {
				//console.log('添加监听事件');
				window.addEventListener('scroll', eventFn);
				window.addEventListener('wheel', eventFn);
				window.addEventListener('resize', eventFn);
				store.commit('handleAffixEventCanAdd', true);
			}
		});
		onBeforeUnmount(() => {
			window.removeEventListener('scroll', eventFn);
			window.removeEventListener('wheel', eventFn);
			window.removeEventListener('resize', eventFn);
			store.commit('handleAffixEventCanAdd', false);
		});

		//功能：实现手机端折叠展示
		const isFoldForMobile: Ref<boolean> = ref(true); //true折叠，false展开
		onMounted(() => {
			//console.log($route?.meta?.isUnfoldAll);
			if ($route?.meta?.isUnfoldAll) {
				isFoldForMobile.value = false; //展开
			}
		});
		return { affixMode, domForAffix, isFoldForMobile, t };
	},
});
