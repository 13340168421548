import store from '@/store';

const metaInfo = () => {
    return {
        title: store?.state?.metaInfo?.title,
        meta: [
            {
                name: 'keywords',
                content: store?.state?.metaInfo?.keywords,
            },
            {
                name: 'description',
                content: store?.state?.metaInfo?.description,
            },
        ],
    };
};

export default metaInfo;
