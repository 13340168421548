import {introduce} from '@/config';

const consoleCopyright = () => {
    //公布版权信息
    console.log(`%c${introduce.title} | ${introduce.url}`,
        'color:#5c434a;background:radial-gradient(circle farthest-corner at 5% 95%, #FFEDF2, #ECF2FF);padding: 8px 15px;border-radius:15px 0 15px 0;border:1px dotted #e6a9c4'
    );
    //添加注释
    document.documentElement.insertBefore(document.createComment(`
.oOOOo.  o      O o.OOoOoo    Oo    o.     O o.OOoOoo o      O 
o     o  O      o  O         o  O   Oo     o  O       O      o 
O.       o      O  o        O    o  O O    O  o       o      O 
 \`OOoo.  OoOooOOo  ooOO    oOooOoOo O  o   o  ooOO    OoOooOOo 
      \`O o      O  O       o      O O   o  O  O       o      O 
       o O      o  o       O      o o    O O  o       O      o 
O.    .O o      o  O       o      O o     Oo  O       o      o 
 \`oooO'  o      O ooOooOoO O.     O O     \`o ooOooOoO o      O     
`),document.head);
}


export default consoleCopyright;