export default {
	maxSpreadZoom: 5, // 控制预览图最大的倍数，默认是2倍，我这里改成了原图
	fullscreenEl: true, //控制是否显示右上角全屏按钮
	closeEl: true, //控制是否显示右上角关闭按钮
	tapToClose: true, //点击滑动区域应关闭图库
	shareEl: false, //控制是否显示分享按钮
	zoomEl: true, //控制是否显示放大缩小按钮
	counterEl: true, //控制是否显示左上角图片数量按钮
	arrowEl: true, //控制如图的左右箭头（pc浏览器模拟手机时）
	tapToToggleControls: true, //点击应切换控件的可见性
	clickToCloseNonZoomable: true, //点击图片应关闭图库，仅当图像小于视口的大小时

	//index: 0, // 开始幻灯片索引。`0`是第一张幻灯片。必须是整数，默认为0
	showAnimationDuration: 333, // 图片放大预览的过渡动画时间，必须是数字，默认为333毫秒
	hideAnimationDuration: 333, // 顾名思义，图片隐藏的过渡动画时间，必须是数字，默认为333毫秒
	bgOpacity: 0.9, //背景的不透明度
	//maxSpreadZoom: 2, // 执行展开（缩放）手势时的最大缩放级别。`2`意味着图像可以从原始尺寸放大 2 倍
	loop: !1, // 默认循环轮播图片
	pinchToClose: !0, //当图片缩小时，退出相册集
	closeOnScroll: !0, //当滚动鼠标的时候，退出相册，这边默认true，建议false
	modal: !0, // 控制 图片 是否应展开以占据整个视口。如果为 false，则 PhotoSwipe 元素将采用模板的定位父级的大小 ，默认为true
	//fullscreenEl: false, //控制是否显示右上角全屏按钮
	//closeEl: false, //控制是否显示右上角关闭按钮
	//tapToClose: false, //点击滑动区域应关闭图库
	//shareEl: false, //控制是否显示分享按钮
	//zoomEl: false, //控制是否显示放大缩小按钮
	//counterEl: true, //控制是否显示左上角图片数量按钮
	//tapToToggleControls: true, //点击应切换控件的可见性
	//clickToCloseNonZoomable: true, //点击图片应关闭图库，仅当图像小于视口的大小时
	indexIndicatorSep: ' / ', //图片数量的分隔符
};
