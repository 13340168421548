import { onBeforeUnmount, onMounted } from '@vue/composition-api';

export default {
    props: {
        click: {
            type: Function,
            required: false,
        },
        type: {
            type: String,
            required: false,
            validator: (value: any) => {
                return ['primary', 'danger', 'text', 'default', ''].includes(
                    value
                );
            },
        },
        htmlType: {
            type: String,
            required: false,
        },
        ico: {
            type: String || Object,
            required: false,
        },
        size: {
            type: String,
            required: false,
            validator: (value: any) => {
                return ['large', 'middle', 'small'].includes(value); //indexOf(value) !== -1;
            },
        },
        title: {
            type: String,
            required: false,
        },
    },
    setup(props: any, { emit }: any) {
        //绑定按钮点击事件
        const _click = (...arg: any) => {
            emit('click', ...arg);
        };
        //按钮提示
        let t: number | undefined = undefined;
        let tIn: number | undefined = undefined;
        const nid = parseInt('' + Math.random() * 10000000000).toString(16); //nanoid();
        const showTitle = (msg: string | undefined, event: MouseEvent) => {
            if (typeof msg !== 'string' || !msg) {
                return;
            }
            clearTimeout(tIn);
            hideTitle();
            t = setTimeout(() => {
                const tipNode = document.createElement('div');
                tipNode.setAttribute('data-only-hash', nid);
                tipNode.setAttribute('data-btn-tip', '');
                tipNode.className = 'btn-exact-tip-node';
                tipNode.style.top = `${'' + (event.y + 30 * 1)}px`;
                tipNode.style.left = `${'' + (event.x + 0 * 1)}px`;
                const tipNodeSpan = document.createElement('span');
                tipNodeSpan.innerText = msg;
                tipNode.appendChild(tipNodeSpan);
                document.body.appendChild(tipNode);
                tIn = setTimeout(() => {
                    typeof hideTitle === 'function' && hideTitle();
                }, 2000);
            }, 500);
        };
        const hideTitle = () => {
            const DOMs = document.querySelectorAll(`div[data-btn-tip]`);
            DOMs.forEach(item => {
                document.body.removeChild(item);
            });
            clearTimeout(t);
        };
        onBeforeUnmount(() => {
            hideTitle();
        });
        return { _click, showTitle, hideTitle };
    },
};
