import { defineComponent, onMounted, ref } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import FetchData from '@/components/FetchData';

import { parseTime } from '@/public/methods';
import { req } from '@/api';

//import { useI18n } from '@/i18n';
//const i18n = useI18n();

export default defineComponent({
	components: { FetchData },
	setup(props: any, { root }: any) {
		const { $message } = root;
		const t = root.$t.bind(root);
		//const { $t } = root;
		//console.log(t('hello'));
		//alert($t('hello'));
		const isTabHot: Ref<boolean> = ref(true);
		const dataHot: Ref<any> = ref([
			{
				onlyid: '@@sadhwqui',
				title: '感谢生命中的美意',
				time: 1684216548000,
				readCount: 55,
			},
			{
				onlyid: '@@qwu74jkflo',
				title: '花开半夏，物非人也非',
				time: 1584216538000,
				readCount: 55,
			},
			{
				onlyid: '@@qwu74wsaqwjkflo',
				title: '八年，可以彻底忘记一个人',
				time: 1584206538000,
				readCount: 15,
			},
			{
				onlyid: '@@qwu7werqt4jkflo',
				title: 'html5中nav标签（导航链接）的详细介绍1111',
				time: 1584226538000,
				readCount: 14,
			},
			{
				onlyid: '@@qqtwu74jkflo',
				title: '我盼有一天能和你相见',
				time: 1584316538000,
				readCount: 53,
			},
			{
				onlyid: '@@qwu74jkflwqretro',
				title: '一天，一年，一生',
				time: 1554216538000,
				readCount: 99,
			},
			{
				onlyid: '@@qwu74jkflwqerwqo',
				title: '花开半夏，物非人也非',
				time: 1584216538000,
				readCount: 25,
			},
			{
				onlyid: '@@qwu74jkflowqqqqq',
				title: '花开半夏，物非人也非',
				time: 1584216538000,
				readCount: 38,
			},
		]);
		const dataRand: Ref<any[]> = ref([]);
		const touchData = (data: any) => (dataHot.value = data);
		onMounted(() => {
			req('BLOG_ARTICLE_RANDOM')
				.then(data => /*console.log(data)*/ data)
				.catch(error => {
					$message.error(error);
				});
		});
		return { dataHot, parseTime, isTabHot, t, touchData };
	},
});
