import { defineComponent, onMounted, ref, Ref, watch } from '@vue/composition-api';

export default defineComponent({
	props: {
		defaultValue: {
			type: String,
			default: '',
			required: false,
		},
	},
	setup(props: any, { emit }) {
		const inputValue: Ref<string> = ref('');
		const doChange = () => {
			emit('change', inputValue.value);
		};
		const doBlur = () => {
			emit('blur', inputValue.value);
		};
		onMounted(() => {
			inputValue.value = props.defaultValue;
		});
		watch(
			() => inputValue.value,
			newValue => emit('change', newValue),
			{ immediate: true }
		);
		return { inputValue, doBlur, doChange };
	},
});
