
















import { defineComponent } from '@vue/composition-api';
import { parseTime } from '@/public/methods';

export default defineComponent({
	props: {
		nickname: {
			type: String,
			default: '仙娥',
			required: false,
		},
		time: {
			type: Number,
			default: new Date().getTime(),
			required: false,
		},
		from: {
			type: String,
			default: undefined,
			required: false,
		},
	},
	setup(props, { root }: any) {
		const t = root.$t.bind(root);
		return {
			parseTime,
			t,
		};
	},
});
