const compatPolyfill = () => {
    try {
        if (!window.crypto) {
            window.crypto = window.msCrypto;
        }
        const DOM = (document.body || document.querySelector('body'))!;
        if (typeof DOM === 'object') {
            if (!DOM.__proto__.remove) {
                DOM.__proto__.remove = function () {
                    DOM.parentNode.removeChild(DOM);
                };
            }
        }
        if (!Array.prototype.find) {
            Array.prototype.find = function (callback: any) {
                return callback && (this.filter(callback) || [])[0];
            };
        }
    } catch (e) {
        //console.error(e);
    }
};

export default compatPolyfill;
