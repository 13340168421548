import { consoleDeterOutput } from '@/config';
import pub from '@/public/pub';
import { ERROR_REPORTING } from '@/api';
import axios from 'axios';

const isReport = (): boolean => {
    //重复性报错的节流
    let res = true;
    const keyName = 'lastErrorReport';
    const lastTime = pub.get(keyName);
    if (
        typeof lastTime === 'number' &&
        new Date().getTime() - lastTime < consoleDeterOutput.reportInterval
    ) {
        res = false;
    } else {
        pub.set(keyName, new Date().getTime());
    }
    return res;
};

const consoleRewrite = () => {
    if (!consoleDeterOutput.isRewrite) {
        return;
    }
    if (/Trident/.test(navigator.userAgent)) {
        return;
    }
    try {
        const { log, warn, error } = console;
        console.log = (...arg) => {
            const { have, regExp } = consoleDeterOutput;
            let deter = false;
            have.forEach(
                item => ('' + arg[0]).includes(item) && (deter = true)
            );
            regExp.forEach(item => item.test('' + arg[0]) && (deter = true));
            deter || log(...arg);
        };
        console.info = console.log;
        console.warn = (...arg) => {
            if (isReport()) {
                axios
                    .post(ERROR_REPORTING, {
                        level: 'warning',
                        detail: '' + JSON.stringify(arg),
                    })
                    .catch(error => {
                        //
                    });
            }
            consoleDeterOutput.isErrorHidden || warn(...arg);
        };
        console.error = (...arg) => {
            if (isReport()) {
                axios
                    .post(ERROR_REPORTING, {
                        level: 'error',
                        detail: '' + JSON.stringify(arg),
                    })
                    .catch(error => {
                        //
                    });
            }
            consoleDeterOutput.isErrorHidden || error(...arg);
        };
    } catch (e) {
        //
    }
};

export default consoleRewrite;
