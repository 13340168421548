import { defineComponent, ref, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

export default defineComponent({
    props: {
        isLoading: {
            type: Boolean,
            required: false,
            default: true,
        },
    },
    setup(props) {
        const isInLoading: Ref<boolean> = ref(!!props.isLoading);
        const isAppear: Ref<boolean> = ref(false);
        watch(
            () => props.isLoading,
            value => {
                isInLoading.value = value;
                if (!value) {
                    isAppear.value = true;
                }
            }
        );
        return { isInLoading, isAppear };
    },
});
