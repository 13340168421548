import {
    defineComponent,
    onMounted,
    reactive,
    watch,
} from '@vue/composition-api';
import type { Data } from '@vue/composition-api';

interface TablinePosition {
    left: number | string;
    width: number | string;
}

export default defineComponent({
    props: {
        list: {
            type: Array,
            default: () => ['无内容'],
            required: false,
        },
        defaultKey: {
            type: String,
            default: '',
            required: false,
        },
    },
    setup(props: any, { root }: any) {
        let { list } = props;
        const { defaultKey } = props;
        const onlyhash = (Math.random() * 999999).toString(16);
        const tablinePosition: Data = reactive<TablinePosition>({
            left: 0,
            width: 0,
        });
        //console.log(list);
        const { $message } = root;
        const handleAction = (item: any) => {
            const { action, key } = item;
            if (typeof action === 'function') {
                const eventResult = action();
                if (
                    typeof eventResult === 'object' &&
                    typeof eventResult.then === 'function' &&
                    typeof eventResult.catch === 'function'
                ) {
                    eventResult
                        .then(() => {
                            changeActive(key);
                        })
                        ?.catch((error: any) => {
                            if (error !== 'ignore') {
                                $message.error('' + error);
                            }
                        });
                } else {
                    changeActive(key);
                }
            } else {
                $message.error('未绑定事件');
            }
            //console.log(action);
        };
        const changeActive = (key: any) => {
            //console.log("'将活动点切换至" + key);
            if (list.filter((item: any) => item.key === key).length !== 1) {
                //如果没有找到key值
                return;
            }
            list = list.map((item: any) => {
                item.isActive = item.key === key;
                return item;
            });

            const element = document.querySelector(
                `[data-onlyhash="${onlyhash}"] [data-key="${key}"]`
            );
            if (typeof element !== 'undefined' && element !== null) {
                tablinePosition.left = (element as any).offsetLeft + 'px';
                tablinePosition.width = (element as any).clientWidth + 'px';
                /*
                console.log(
                    element.parentNode,
                    element.scrollLeft,
                    element.clientWidth,
                    (element as any).offsetLeft
                );
                */
            }
        };
        onMounted(() => {
            changeActive(defaultKey);
        });
        return {
            handleAction,
            onlyhash,
            tablinePosition,
        };
    },
});
