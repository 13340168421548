import { render, staticRenderFns } from "./Tip.vue?vue&type=template&id=2cc9c3fa&scoped=true&"
import script from "./Tip.ts?vue&type=script&lang=ts&"
export * from "./Tip.ts?vue&type=script&lang=ts&"
import style0 from "./Tip.scss?vue&type=style&index=0&id=2cc9c3fa&lang=scss&scoped=true&"
import style1 from "./global.scss?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cc9c3fa",
  null
  
)

export default component.exports