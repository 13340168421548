import store from '@/store';
import { app as appConfig } from '@/config';

const commitEvents = () => {
	//功能，双击屏幕进行页面滚动
	let scrollInterval: number | undefined = undefined;
	const scrollEvent = () => {
		if (typeof document.documentElement.scrollTo === 'function') {
			document.documentElement.scrollTo(document.documentElement.scrollLeft, document.documentElement.scrollTop + 1);
		} else {
			document.documentElement.scrollTop += 1;
		}
		//document.documentElement.scrollTop += 1;
		//console.log('触发');
		if (document.documentElement.scrollTop >= document.documentElement.scrollHeight - document.documentElement.clientHeight) {
			clearscrollInterval();
		}
	};
	const clearscrollInterval = () => {
		clearInterval(scrollInterval);
	};
	document.documentElement.addEventListener('dblclick', (event: any) => {
		clearscrollInterval();
		if (event.target && event.target.getAttribute && event.target.getAttribute('data-allow-auto-scroll')) {
			scrollInterval = setInterval(() => {
				scrollEvent();
			}, 30);
		}
	});
	document.documentElement.addEventListener('wheel', clearscrollInterval);
	document.documentElement.addEventListener('mousedown', clearscrollInterval);
	document.documentElement.addEventListener('mousemove', clearscrollInterval);

	//功能，页面复制时添加版权信息
	const copyEvent = (event: Event) => {
		if ((window as any).__allowCopy) {
			return;
		}
		if (appConfig.isCopyCarryCopyright) {
			//
			let isSuccess = true;
			const copyrightText = '\r\n\r\n ---- \r\n' + document.title + '\r\n' + document.location.href + '';
			const clipboardData = (event as any)?.clipboardData || (window as any)?.clipboardData;
			const selection = (window as any)?.getSelection() || (document as any)?.selection?.createRange()?.text;
			const text = selection?.toString() || '' + selection;
			if (text) {
				if (clipboardData) {
					// && !navigator.userAgent.includes('Trident')
					event?.preventDefault();
					try {
						clipboardData.setData('text/plain', '' + text + copyrightText);
					} catch (e) {
						try {
							clipboardData.setData('text', '' + text + copyrightText);
						} catch (e) {
							isSuccess = false;
						}
					}
				} else {
					const newDiv = document.createElement('div');
					document.body.appendChild(newDiv);
					newDiv.innerHTML = text + copyrightText;
					selection.selectAllChildren(newDiv);
					window.setTimeout(() => {
						document.body.removeChild(newDiv);
					}, 1);
				}
				store.commit('onPageCopy', isSuccess);
			}
		}
	};
	document.addEventListener('copy', copyEvent);

	//功能，切换窗口时改变标题
	(() => {
		let cacheTitle = '';
		document.addEventListener('visibilitychange', () => {
			if (document.visibilityState === 'hidden') {
				cacheTitle = document.title;
				document.title = 'O(∩_∩)O~~';
			} else {
				document.title = cacheTitle ? cacheTitle : document.title;
			}
		});
	})();

	//功能，禁止私自改动主题
	(() => {
		try {
			const attrListener = new MutationObserver(mutations => {
				const colorDom = mutations[0].target;
				const color = (colorDom as any).getAttribute('data-theme');
				if (['light', 'dark'].includes(color)) {
					return;
				}
				//alert('DOM结构树被恶意篡改');
				//console.log(color);
				(colorDom as any).setAttribute('data-theme', 'light');
			});
			attrListener.observe(document.querySelector('html')!, { attributes: true, attributeFilter: ['data-theme'] });
		} catch (e) {
			//
		}
	})();

	//功能，进入页面时跟随系统的主题（深色模式）
	(() => {
		const isDarkMode = !!window?.matchMedia('(prefers-color-scheme: dark)')?.matches;
		store.commit('toggleDarkMode', isDarkMode);
	})();
};

export default commitEvents;
