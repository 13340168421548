import { ref, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

import Loading from '@/components/Loading';
import Error from '@/components/Error';
import { Btn } from '@/components/UI';
//import axios from 'axios';

export default {
    name: 'PageContent',
    components: { Loading, Error, Btn },
    /*
    //当进入组件之前，执行 beforRouteEnter 路由钩子函数
    beforeRouteEnter(to: any, from: any, next: any) {
        console.log('beforRouteEnter');
        console.log(this); // 结果为undefined，因为在执行beforRouteEnter时候，组件还没有被创建出来；先执行beforRouteEnter，再执行beforeCreate
        next((vm: any) => {
            //参数vm就是当前组件的实例。
            vm.test = '我被改变了';
        });
    },
    beforeRouteUpdate(to: any, from: any, next: any) {
        console.log('beforeRouteUpdate');
        next();
    },
    beforeRouteLeave(to: any, from: any, next: any) {
        //离开组件的时候触发
        //什么都不写的时候，不会离开(走下一步)
        next();
    },
    */
    setup(props: any, { root }: any) {
        //加载缓慢时弹出loading动画
        let timer: number | undefined = undefined;
        const { $router } = root;
        const isLoading: Ref<boolean> = ref(false);
        const isDone: Ref<boolean> = ref(true);
        const { $store } = root;
        $router.beforeEach((to: any, from: any, next: any) => {
            $store?.commit('updateOutLineState');
            clearTimeout(timer);
            timer = setTimeout(() => {
                (NProgress as any).start();
                isDone.value = false;
                isLoading.value = true;
            }, 500);
            //setTimeout(next, 5000);
            if (!$store?.state?.isOutLine) {
                next();
            }
        });
        $router.afterEach(() => {
            (NProgress as any).done();
            clearTimeout(timer);
            isDone.value = true;
            setTimeout(() => {
                isLoading.value = false;
            }, 1);
        });

        //请求进度条
        /*
        axios.interceptors.request.use(
            config => {
                (NProgress as any).start(); // 设置加载进度条(开始..)
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );
        const handleclick = () => {
            axios.get('/api/suspense').finally(() => {
                (NProgress as any).done();
            });
        };
        */
        return { isLoading, isDone };
    },
};
