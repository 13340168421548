const dataSkeleton = {
    ALL: {
        config: {
            console: {
                isLock: false, //控制台上锁
                lockTime: -1, //控制台上锁时间
                unlockTime: -1, //控制台解锁时间(戳)
            },
            render: {
                isBrowserRender: true, //开启浏览器前端渲染
            },
            dynamicRoutes: {
                routes: [], //动态添加路由
            },
        },
        web: {
            title: '仙娥小站',
            description: '记录生活，发现美好', //
            url: 'sheaneh.com',
            domain: 'sheaneh.com', //域名
            tags: ['个人博客', '程序员'],
            language: 'zh-cn', //zh-cn,en,ar,ur,sh
            model: 'default', //网站模板名称，默认default，就是此模板，其他的都是后端渲染的模板
            theme: 'auto', //网站的夜间模式(自动(跟随系统),时间段(日出日落,结合IP,经纬度,天气判断),亮调，暗调)
            createTime: new Date(2008, 6, 12, 21, 0, 0).getTime() / 1000,
            buildTime: new Date(2021, 3, 28, 21, 0, 0).getTime() / 1000,
            author: '仙娥',
            authorDescription: '花遇时光❤莫逆于心',
            avatar: '/avatar.png',
        },
        appInfo: {
            navBar: {
                //导航条
                data: [],
            },
            homeLayout: {
                //首页布局
                data: [],
            },
            openCanvas: {
                //开场动画
                renderMode: 'flash', //'flash'|'canvas'|'webgl',//渲染模式
                id: 'start',
            },
            notice: {
                isShow: false,
                showTime: [
                    //公告栏显示的时间段
                    { start: 8, end: 20, twice: 3 },
                ],
                showTwice: -1, //公告栏提示的时机
                content: '欢迎您来到本站', //公告栏的内容
            },
            count: {
                //数量，-1是未知
                phrase: -1, //说说的数量
                blogArticle: -1, //文章的数量
                blogRead: -1, //文章的总阅读量
                blogCategory: -1, //文章分类的数量
                blogWord: -1, //所有文章的总字数
                blogComment: -1, //文章评论的数量
                blogPraise: -1, //文章点赞的数量
                blogTag: -1, //文章的标签数量
                photo: -1, //相册里照片的数量
                photoAlbum: -1, //相册的数量
                music: -1, //音乐的数量
                link: -1, //友情链接的数量
                friend: -1, //朋友的数量
                guestbook: -1, //留言的数量
                visitor: -1, //访客的数量
            },
        },
        activity: {
            //获取一部分动态
            data: [],
        },
        blogArticleHot: {
            //热门文章
            data: [],
        },
        photoHot: {
            //热门图片
            data: [],
        },
        photoVideoHot: {
            //热门短视频
            data: [],
        },
        friendHot: {
            //我的好友(仅主页展示的几个，仅获取简易的信息)
            data: [],
        },
        commentRecent: {
            //最新评论（包括留言板，仅获取最新的几个）
            data: [],
        },
        customQRCode: [
            'mobileRead', //使用手机阅读
            {
                text: '欢迎进群交流',
                value: 'https://qm.qq.com/cgi-bin/qm/qr?k=TTYwmYwOhIzdx5T9N3cnbqeZ4a0BbJcJ&authKey=xit7BLTwoROoofujthXV8t+jXZ+jh7chBYv+GDohDFU/qF39bd5tL0ek7zRgRftT&noverify=0',
            },
        ],
        //自定义页面底部的二维码
    },
    BLOG_ARTICLE_ARTICLE: {
        onlyid: '',
        title: '',
        time: -1, //时间
        local: {}, //地点
        category: '', //分类，这里英文名，程序根据英文名找中文名
        keywords: [], //关键词
        description: '',
        diyurl: null, //自定义URL
        content: '',
        contentType: 'markdown', //默认markdown，也可以是draft对象等形式
        readCount: -1, //阅读量
        commentCount: -1, //评论量
        praiseCount: -1, //点赞量
        favoriteCount: -1, //收藏量
        shareCount: -1, //分享量
        isRecommend: false, //是否推荐
        isTop: false, //是否置顶
        //isHidden:false,//是否隐藏
        //hiddenDesc:'',//隐藏的描述
        isReprint: true, //是否转载
        reprintFrom: '', //转载来源
        media: {}, //文章内的媒体
    },
    MUSIC_BGM: {
        //背景音乐
        playlist: [],
    },
    ABOUT_ALL: {
        md: {
            me: '这家伙很懒，什么也没写(可在后台管理系统编辑)',
            web: '这家伙很懒，什么也没写(可在后台管理系统编辑)',
            statement: '这家伙很懒，什么也没写(可在后台管理系统编辑)',
        },
    },
};
export default dataSkeleton;
