






import { ref, computed, getCurrentInstance } from '@vue/composition-api'; // onMounted,
import type { Ref } from '@vue/composition-api';
import { req } from '@/api';
import store from '@/store';
//import axios from 'axios';
import App from '@/components/App';
//import Loading from '@/components/Loading';

export default {
	components: { App },
	setup() {
		const isDenied: Ref<boolean> = ref(false);
		const deniedMsg: Ref<string> = ref('');
		//解决图片预览后退的问题
		/*
		try {
			const { proxy } = getCurrentInstance()!;
			const { $preview } = proxy as any;
			$preview.on('imageLoadComplete', (e: any, item: any) => {
				//console.log(111);
				/、*
				let _preview = $preview.self;
				let lookUrl = window.location.href;
				if (window.location.href.indexOf('#showImage') === -1) {
					lookUrl = lookUrl + '#showImage';
					window.history.pushState(null, null as any, lookUrl);
				}
				_preview.listen('close', function () {
					if (window.location.href.indexOf('#showImage') !== -1) {
						window.history.back();
					}
				});
				window.onhashchange = function () {
					if (_preview !== null && _preview !== undefined) {
						_preview.close();
						_preview = null;
					}
				};
				*、/
				/、*
				window.location.hash = 'showImage';
				let _preview = $preview.self;
				_preview.listen('close', () => {
					window.location.hash = '';
				});
				window.onhashchange = () => {
					if (_preview) {
						_preview.close();
						_preview = null;
					}
				};
				*、/
			});
		} catch (e) {
			alert('失败');
		}
		*/
		//const isLoading: Ref<boolean> = ref(true);
		const appComponent = computed(() => (isDenied.value ? () => import(/*webpackChunkName:'_s_h_e_a_n_e_h_-chunk-no_access'*/ '@/components/NoAccess') : App));
		/*
        axios
            .get(ALL)
            .then(res => {
                const { data } = res;
                if (typeof data !== 'object' || data.code !== 0) {
                    throw (
                        '' +
                        (data?.code ? `[${data.code}]` : '') +
                        (data?.msg || '未知错误')
                    );
                }
            })
            .catch(error => {
                deniedMsg.value = '' + error;
                isDenied.value = true;
            });
        */
		//watch立即监视 immediate 深度监视deep

		req('ALL')
			.then(data => {
				//console.log('获取了数据', data);
				//将data数据写入vuex状态管理器
				store.commit('initAllData', data);
			})
			.catch(error => {
				deniedMsg.value = '' + error;
				isDenied.value = true;
			});
		/*
        setTimeout(() => {
            console.log(store.state.data.all);
        }, 1000);
        */
		const isIE = /Trident/.test(navigator.userAgent);
		return {
			isDenied,
			deniedMsg,
			appComponent,
			isIE,
		};
	},
};
