import { getArea, req } from '@/api';
import { defineComponent, onMounted, ref, Ref } from '@vue/composition-api';

import { Skeleton } from '@/components/UI';

export default defineComponent({
	props: {
		area: {
			type: String,
			default: '',
			require: true,
		},
		param: {
			type: Object,
			default: () => ({}),
		},
		isCache: {
			type: Boolean,
			default: false,
		},
		showMessage: {
			type: Boolean,
			default: false,
		},
		isSkeleton: {
			//是否骨架屏
			type: Boolean,
			default: false,
		},
	},
	emits: ['touchData'],
	components: { Skeleton },
	setup(props, { root, emit }: any) {
		const { $message } = root;
		const t = root.$t.bind(root);
		const isLoading: Ref<boolean> = ref(true);
		const isError: Ref<boolean> = ref(false);
		const errorMsg: Ref<string> = ref('');
		const responseData: Ref<any> = ref();
		const isEmptyObject = (obj: any) => {
			if (typeof obj !== 'object') {
				return true;
			}
			if (Object.getOwnPropertyNames(obj).length === 0) {
				return true;
			}
			if (JSON.stringify(obj || {}) == '{}') {
				return true;
			}
			return false;
		};
		const init = () => {
			isLoading.value = true;
			isError.value = false;
			const reqPromise = props.isCache && isEmptyObject(props.param) ? getArea(props.area) : req(props.area, { ...props.param });
			reqPromise
				.then(data => {
					//console.log('这是', data);
					responseData.value = data;
					emit('touchData', data);
				})
				.catch(error => {
					if (props.showMessage) {
						$message.error('' + error);
					}
					isError.value = true;
					errorMsg.value = '' + error;
				})
				.finally(() => {
					isLoading.value = false;
				});
		};
		onMounted(() => {
			init();
		});
		return { isLoading, isError, errorMsg, responseData, init, t };
	},
});
