import { ref, onMounted, onBeforeUnmount, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import store from '@/store';

const CanvasEffect = () => import('./canvas-effect');
const CanvasEffectDark = () => import('./canvas-effect-dark');

export default {
	components: { CanvasEffect, CanvasEffectDark },
	setup() {
		const time: Ref<string> = ref('');
		let t: number | undefined = undefined;
		let colon: string | undefined = ':';
		onMounted(() => {
			t = setInterval(() => {
				const timeObj = new Date();
				//colon = colon === ' ' ? ':' : ' ';
				colon = ':';
				time.value = '' + (timeObj.getHours() + '').padStart(2, '0') + colon + (timeObj.getMinutes() + '').padStart(2, '0') + colon + (timeObj.getSeconds() + '').padStart(2, '0');
			}, 500);
		});
		onBeforeUnmount(() => {
			clearInterval(t);
		});
		//根据白天黑夜模式切换组件
		const canvasEffectComponent: Ref<string> = ref('CanvasEffect');
		watch(
			() => store.state.darkMode,
			(value: boolean) => {
				canvasEffectComponent.value = value ? 'CanvasEffectDark' : 'CanvasEffect';
			},
			{ immediate: true }
		);
		return {
			time,
			canvasEffectComponent,
		};
	},
};
