import { reactive, PropType, ref, Ref, watch } from '@vue/composition-api';
//import type {Re} from '@vue/composition-api';

interface Count {
	[propName: string]: number;
}
export default {
	name: 'Profile',
	setup(props: any, { root }: any) {
		const { $store } = root;
		const t = root.$t.bind(root);
		const data = reactive({
			name: '仙娥',
			desc: '花遇时光，莫逆于心',
			description: [
				{
					icon: 1,
					text: '90后网页设计爱好者',
				},
				{
					icon: 2,
					text: '因为热爱，所以不曾放弃',
				},
				{
					icon: 3,
					text: '感谢您的来访',
				},
			],
		});
		watch(
			() => $store.state.language,
			newValue => {
				setTimeout(() => {
					data.name = t('profile.nickName');
					data.desc = t('profile.description');
				}, 1);
			},
			{ immediate: true }
		);
		const count: Ref<Count> = ref({
			phrase: -1,
			blogArticle: -1,
			photo: -1,
			visitor: -1,
		});
		const formatNumber = (num: number): string => {
			num = Number(num);
			if (isNaN(num)) {
				return '-';
			}
			switch (true) {
				case num < 0:
					return '(未统计)';
				case num >= 10000:
					return `${Math.floor(num / 10000)}万+`;
				case num >= 2000 && num < 10000:
					return `${Math.floor(num / 1000)}千+`;
				case num > 999:
					return '999+';
				default:
					return '' + num;
			}
		};
		watch(
			() => $store.state.data.all,
			newValue => {
				const countUpdate = newValue?.appInfo?.count || {};
				count.value = { ...count.value, ...countUpdate };
			},
			{ immediate: true, deep: true }
		);
		//console.log('这是Profile组件里的prop', props);
		//const { nopadding } = props;
		return {
			data,
			count,
			formatNumber,
			//nopadding,
			t,
		};
	},
};
