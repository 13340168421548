const state:any = {};
/*
export function isValidKey(key: string | number | symbol, object: object): key is keyof typeof object {
    return key in object;
}
*/
const pub = {
    set(key:string,value:any){
        (state as any)[key] = value;
        return value;
    },
    get(key:string){
        return (state as any)[key] || null;
    },
    del(key:string){
        delete (state as any)[key]
        return null;
    }
}
export default pub;