import { onBeforeUnmount, onMounted, ref, reactive } from '@vue/composition-api';
import type { Ref, Data } from '@vue/composition-api';

import Vuex from 'vuex';

import Logo from '@/components/Logo';

import Btn from '@/components/UI/Btn';

type VoidMethodEvent = (event: Event) => void;
type VoidMethodBoolean = (isOpen: boolean) => void;
//const { Btn } = UI;

export default {
	name: 'Navbar',
	components: {
		Logo,
		Btn,
	},
	props: {
		isNormally: {
			type: Boolean,
			require: false,
			default: false,
		},
	},
	setup(props: any, { root }: any) {
		const { state, commit } = root.$store;
		const { $tip, $router } = root;
		const t = root.$t.bind(root);
		//定义导航区右侧按钮的事件
		const methods = (eventName: string) => {
			switch (eventName) {
				case 'search':
					return () => {
						//alert('使用搜索');
						$router.push({
							name: 'Search',
						});
					};
				case 'music':
					return () => {
						commit('toggleMusicPlayer');
						//alert('打开或关闭音乐');
					};
				case 'language':
					return () => {
						//alert('切换语言');
						commit('changeLanguage');
						setTimeout(() => {
							$tip.bigInfo({
								content: t('changeSuccessInfo'),
								icon: 'world1',
							});
						}, 1);
					};
				case 'toggleDark':
					return () => {
						commit('toggleDarkMode');
						if (state.darkMode) {
							//console.log('已切换至深色模式');
							$tip.bigInfo({
								content: t('toggleTheme.dark'), //'已切换至深色模式',
								icon: 'moon8',
							});
						} else {
							//console.log('已切换至浅色模式');
							$tip.bigInfo({
								content: t('toggleTheme.light'), //'已切换至浅色模式',
								icon: 'sunny',
							});
						}
					};
				default:
					return () => {
						alert('错误');
					};
			}
		};
		//定义导航栏数据
		const data: Data = reactive({
			nav: [
				{
					t: 'home',
					text: '首页', //t('navLink.home'),
					to: '/',
				},
				{
					t: 'blog',
					text: '博客', //t('navLink.blog'),
					to: '/blog',
				},
				{
					t: 'photo',
					text: '相册',
					to: '/photo',
				},
				{
					t: 'music',
					text: '音乐',
					to: '/music',
				},
				{
					t: 'flash',
					text: '闪客',
					to: '/flash',
				},
				{
					t: 'links',
					text: '友链',
					to: '/links',
				},
				{
					t: 'about',
					text: '关于我',
					to: '/about',
				},
				{
					t: 'lab',
					text: '实验室',
					to: '/lab',
				},
			],
			btns: [
				{
					icon: 'shico-magnifier',
					t: 'search',
					text: '搜索', //绑定键盘ctrl+k
					click: methods('search'),
				},
				{
					icon: 'shico-music2',
					t: 'music',
					text: '音乐',
					click: methods('music'),
				},
				{
					icon: 'shico-world1',
					t: 'language',
					text: '语言',
					click: methods('language'),
				},
				{
					icon: 'shico-moon',
					t: 'theme',
					text: '深色/浅色模式', //state.darkMode ? '深色' : '浅色',
					click: methods('toggleDark'),
				},
			],
		});
		//导航栏随着滚动条的滚动发生样式的改变
		const isInScroll: Ref<boolean> = ref(false);
		const changeIsInScroll: VoidMethodEvent = (event: Event): void => {
			const osTop: number = document.documentElement!.scrollTop || document.body!.scrollTop;
			isInScroll.value = osTop !== 0;
		};
		onMounted((): void => {
			window.addEventListener('scroll', changeIsInScroll);
		});
		onBeforeUnmount((): void => {
			window.removeEventListener('scroll', changeIsInScroll);
		});
		//定义导航栏的手机端
		const isMobileMenuOpen: Ref<boolean> = ref(false);
		const toggleMobileMenu: VoidMethodBoolean = (isOpen: boolean): void => {
			isMobileMenuOpen.value = isOpen;
		};
		return { state, data, isInScroll, toggleMobileMenu, isMobileMenuOpen, t };
	},
};
