import {
    onBeforeUnmount,
    onMounted,
    computed,
    ref,
    Ref,
    watch,
} from '@vue/composition-api';

import { Btn } from '@/components/UI';

import SvgWave from '@/svg/wave';

export default {
    components: { Btn, SvgWave },
    setup() {
        //监听并定义返回顶部功能
        let t: number | undefined = undefined;
        const goTopTime = 500; //500毫秒完成
        const frame = 17; //每帧17毫秒
        const handleScrollTop = () => {
            /*
             */ //||typeof window.getComputedStyle(document.body).scrollBehavior === 'undefined'
            if (
                typeof window.getComputedStyle(document.body).scrollBehavior ===
                'undefined'
            ) {
                const stopScroll = () => {
                    clearInterval(t);
                    window.removeEventListener('wheel', stopScroll);
                };
                window.addEventListener('wheel', stopScroll);
                //如果浏览器不支持平滑滚动
                t = setInterval(() => {
                    if (document.documentElement.scrollTop === 0) {
                        stopScroll();
                        return;
                    }
                    const tarY =
                        document.documentElement.scrollTop -
                        Math.ceil(goTopTime / frame);
                    window.scrollTo(0, tarY);
                }, frame);
            } else {
                window.scrollTo({
                    behavior: 'smooth',
                    top: 0,
                });
            }
        };
        const isShow: Ref<boolean> = ref(false);
        const showOpacity: Ref<number> = ref(0);
        window.addEventListener('scroll', () => {
            isShow.value = !!(
                (document.documentElement.scrollTop ||
                    document.body.scrollTop) > 150
            );
            showOpacity.value = (() => {
                const min = 150;
                const max = 150 * 2.5;
                let i =
                    ((document.documentElement.scrollTop ||
                        document.body.scrollTop) -
                        min) /
                    (max - min);
                i = i < 0 ? 0 : i > 1 ? 1 : i;
                return i;
            })();
        });

        //const isMusicPlayerOpen = false; //如果音乐播放器打开，则偏移一段
        onMounted(() => {
            /** */
        });
        onBeforeUnmount(() => {
            /** */
        });

        //根据滚动条位置获取当前所占百分比
        const progress: Ref<number> = ref(0);
        const isFastScroll: Ref<boolean> = ref(false);
        function getScrollProgress(event: any) {
            const oldProgress = progress.value;
            /*
            progress.value =
                (document.documentElement.scrollTop ||
                    document.body.scrollTop) /
                ((document.documentElement.offsetHeight ||
                    document.body.offsetHeight) -
                    (document.documentElement.clientHeight ||
                        document.body.clientHeight));
                        */
            progress.value =
                (document.documentElement.scrollTop ||
                    document.body.scrollTop) /
                ((document.documentElement.scrollHeight ||
                    document.body.scrollHeight) -
                    (document.documentElement.clientHeight ||
                        document.body.clientHeight));
            if (
                Math.abs((progress.value - oldProgress) / progress.value) > 0.01
            ) {
                isFastScroll.value = true;
            } else {
                isFastScroll.value = false;
            }
        }
        watch(
            () => isShow.value,
            isShow => {
                if (isShow) {
                    window.addEventListener('resize', getScrollProgress);
                    window.addEventListener('scroll', getScrollProgress);
                } else {
                    window.removeEventListener('resize', getScrollProgress);
                    window.removeEventListener('scroll', getScrollProgress);
                }
            }
        );
        //监听陀螺仪功能或快速滚动以实现水波特效
        return {
            isShow,
            showOpacity,
            /*isMusicPlayerOpen,*/ handleScrollTop,
            progress,
            isFastScroll,
        };
    },
};
