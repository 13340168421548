import Vue from 'vue';
import Message from './Message.vue';

const messageBox = Vue.extend(Message);

(Message as any).install = function (options: any, type: any) {
    if (typeof options === 'undefined' || !options) {
        options = {
            content: '',
        };
    } else if (typeof options === 'string' || typeof options === 'number') {
        options = {
            content: '' + options,
        };
        if (type !== undefined && options !== null) {
            options.type = type;
        }
    }
    const instance = new messageBox({
        data: options,
    }).$mount();
    const messageContainer =
        document.querySelector('div[data-shui-message]') ||
        (() => {
            const messageContainer = document.createElement('div');
            messageContainer.setAttribute('data-shui-message', '');
            messageContainer.setAttribute('class', 'shui-message-package');
            document.body.appendChild(messageContainer);
            return messageContainer;
        })();
    messageContainer.appendChild(instance.$el);
    //定义一些
    Vue.nextTick(() => {
        instance.visible = true;
    });
};
(Message as any).install.info = (options: any) => {
    (Message as any).install(options, 'info');
};
(Message as any).install.success = (options: any) => {
    (Message as any).install(options, 'success');
};
(Message as any).install.warning = (options: any) => {
    (Message as any).install(options, 'warning');
};
(Message as any).install.error = (options: any) => {
    (Message as any).install(options, 'error');
};
if (!Vue.prototype.$message) {
    Vue.prototype.$message = (Message as any).install;
}

export default Message;
