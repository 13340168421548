import axios from 'axios';
import type { AxiosPromise, AxiosError, AxiosResponse } from 'axios';

import { getApiUrl, getApiMethod } from '../api';
import dataSkeleton from '../data-skeleton';

const errorMsg: any = {
	400: '错误的请求',
	401: '未取得授权',
	403: '拒绝访问',
	404: '页面未找到',
	405: '请求方法不被允许',
	408: '请求超时',
	500: '服务器内部错误',
	502: '无效的响应',
	503: '服务不可用',
};

export const requireMethod = (area: string, params: any = {}, method = 'GET') => {
	const url = getApiUrl(area);
	method = getApiMethod(area) || ('' + method).toUpperCase();
	if (typeof params !== 'object') {
		params = {};
	}
	return new Promise((resolve, reject) => {
		let axiosPromise: AxiosPromise;
		if (typeof url !== 'string' || url === '' || !url) {
			if (typeof url === 'object' && url.msg) {
				reject(url.msg);
			} else {
				reject('无法获取API请求源');
			}
			return;
		}
		if (method === 'POST') {
			axiosPromise = axios.post(url, params);
		} else {
			axiosPromise = axios.get(url, { params });
		}
		axiosPromise
			.then((response: AxiosResponse) => {
				const { data } = response;
				if (typeof data !== 'object') {
					reject('服务器返回了错误的格式:' + typeof data);
				}
				if (data.code === 0 && typeof data.data === 'object') {
					if (typeof (dataSkeleton as any)[area] === 'object') {
						resolve(Object.assign((dataSkeleton as any)[area], data.data));
					} else {
						resolve(data.data);
					}
				} else {
					reject(typeof data.msg === 'string' ? data.msg : '未知错误');
				}
			})
			.catch((error: AxiosError) => {
				let msg = '';
				if (error) {
					if (typeof error.response === 'object' && error.response.status) {
						msg = '网络错误：[' + error.response.status + ']' + (errorMsg[error.response.status] || '未知的网络错误');
					}
				} else {
					msg = '未知错误';
				}
				reject(msg);
			});
	});
};
