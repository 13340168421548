var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn-clear btn-transition",class:[
        (function () {
            switch (_vm.type) {
                case 'primary':
                    return 'btn-class-type-primary';
                case 'danger':
                    return 'btn-class-type-danger';
                case 'text':
                    return 'btn-class-type-text';
                default:
                    return 'btn-class-type-default';
            }
        })(),
        (function () {
            switch (_vm.size) {
                case 'large':
                    return 'btn-class-size-large';
                case 'small':
                    return 'btn-class-size-small';
                default:
                    return 'btn-class-size-middle';
            }
        })() ],attrs:{"type":_vm.htmlType || 'button'},on:{"click":_vm._click,"mouseover":function($event){return _vm.showTitle(_vm.title, $event)},"mouseout":function($event){return _vm.hideTitle()}}},[(
            true ||
            (typeof _vm.ico === 'string' && _vm.ico.substring(0, 6) === 'shico-')
        )?_c('i',{class:_vm.ico}):_vm._e(),_c('span',[_vm._t("default")],2),(typeof _vm.title === 'string' && _vm.title !== '')?_c('span',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"data-btn-title":""}},[_vm._v(_vm._s(_vm.title))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }