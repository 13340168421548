import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
//import Index from "../views/Home.vue";
import Index from '@/pages/Index';
import Error from '@/components/Error';
//import { router as routerConfig } from '@/config';

import pub from '@/public/pub';

import store from '@/store';

import { meta as metaConfig } from '@/config';

/*
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: any) {
    return (originalPush.call(this, location) as any).catch((err: any) => err);
};
*/

//console.log('路由ts引入的配置', metaConfig);

const lazy = (asyncComponent: any): any => {
	return () =>
		asyncComponent()
			.then((resComponent: any) => {
				pub.set('networkDenied', false);
				return resComponent;
			})
			.catch(() => {
				pub.set('networkDenied', true);
			});
};

const proTitle = (title: string | string[] | undefined): string => {
	let res = '';
	if (!title) {
		res = metaConfig.metaInfo.title;
	} else {
		res = `${title}${metaConfig.titleSuffix}`;
	}
	return res;
};

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
	{
		path: '/',
		name: 'Index',
		meta: {
			breadName: '首页',
			isUnfoldAll: true,
		},
		component: Index,
	},
	{
		path: '/index.html',
		name: 'IndexHtml',
		meta: {
			breadName: '首页',
		},
		component: Index,
	},
	{
		path: '/blog',
		name: 'Blog',
		meta: {
			breadName: '博客',
			metaInfo: {
				title: proTitle('博客'),
			},
			isUnfoldAll: true,
		},
		component: lazy(() => import(/*webpackChunkName:'router-blog-component'*/ '@/pages/Blog')),
		children: [
			{
				name: 'BlogArticleDetailId',
				path: 'article/detail/:onlyid',
				meta: {
					breadName: '正文',
					metaInfo: {
						title: proTitle('文章详情'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-blog-article-detail-component'*/ '@/pages/Blog/Article/Detail/')),
			},
			{
				name: 'BlogArticleCategory',
				path: 'article/category',
				meta: {
					breadName: '分类',
					metaInfo: {
						title: proTitle('分类'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-blog-article-category-component'*/ '@/pages/Blog/Article/Category/')),
				children: [
					{
						name: 'BlogArticleCategoryId',
						path: ':onlyid',
						meta: {
							breadName: '详情',
							metaInfo: {
								title: proTitle('分类详情'),
							},
						},
						component: lazy(() => import(/*webpackChunkName:'router-blog-article-category_-component'*/ '@/pages/Blog/Article/Category/')),
					},
				],
			},
			{
				name: 'BlogArticleArchive',
				path: 'article/archive',
				meta: {
					breadName: '归档',
					metaInfo: {
						title: proTitle('归档'),
					},
				},

				component: lazy(() => import(/*webpackChunkName:'router-blog-article-archive_-component'*/ '@/pages/Blog/Article/Archive/')),
				children: [
					{
						name: 'BlogArticleArchiveId',
						path: ':date',
						meta: {
							breadName: '详情',
							metaInfo: {
								title: proTitle('归档详情'),
							},
						},

						component: lazy(() => import(/*webpackChunkName:'router-blog-article-archive_-component'*/ '@/pages/Blog/Article/Archive/')),
					},
				],
			},
			{
				name: 'BlogArticleTag',
				path: 'article/tag',
				meta: {
					breadName: '标签',
					metaInfo: {
						title: proTitle('标签'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-blog-article-tag_-component' */ '@/pages/Blog/Article/Tag/')),
				children: [
					{
						name: 'BlogArticleTagId',
						path: ':onlyid',
						meta: {
							breadName: '详情',
							metaInfo: {
								title: proTitle('标签详情'),
							},
						},
						component: lazy(() => import(/*webpackChunkName:'router-blog-article-tag-component'*/ '@/pages/Blog/Article/Tag')),
					},
				],
			},
			{
				name: 'BlogPhrase',
				path: 'phrase',
				meta: {
					breadName: '说说',
					metaInfo: {
						title: proTitle('说说'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-blog-phrase-component'*/ '@/pages/Blog/Phrase')),
				children: [
					{
						name: 'BlogPhraseDetailId',
						path: 'detail/:onlyid',
						meta: {
							breadName: '详情',
							metaInfo: {
								title: proTitle('说说详情'),
							},
						},
						component: lazy(() => import(/*webpackChunkName:'router-blog-phrase-detail_-component'*/ '@/pages/Blog/Phrase/Detail')),
					},
				],
			},
		],
	},
	{
		path: '/photo',
		//name: 'Photo',
		meta: {
			breadName: '相册',
			metaInfo: {
				title: proTitle('相册'),
			},
			isUnfoldAll: true,
		},
		component: lazy(() => import(/*webpackChunkName:'router-photo-component'*/ '@/pages/Photo')),
		children: [
			{
				path: '',
				name: 'PhotoAlbum',
				meta: {
					//breadName: '相册列表',
					metaInfo: {
						title: proTitle('相册'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-album-component'*/ '@/pages/Photo/Album')),
				children: [
					{
						path: 'album/:onlyid',
						name: 'PhotoAlbumDetail',
						meta: {
							breadName: '相册详情',
							metaInfo: {
								title: proTitle('相册详情'),
							},
						},
						component: lazy(() => import(/*webpackChunkName:'router-album-component'*/ '@/pages/Photo/Album')),
					},
				],
			},
			{
				path: 'timeline',
				name: 'PhotoTimeline',
				meta: {
					breadName: '时间线',
					metaInfo: {
						title: proTitle('时间线'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-album-timeline-component'*/ '@/pages/Photo/Timeline')),
			},
			{
				path: 'shortvideo',
				name: 'PhotoShortVideo',
				meta: {
					breadName: '短视频',
					metaInfo: {
						title: proTitle('短视频'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-shortvideo-component'*/ '@/pages/Photo/Shortvideo')),
				children: [
					{
						path: 'play/:onlyid',
						name: 'PhotoShortVideoPlay',
						meta: {
							breadName: '播放',
							metaInfo: {
								title: proTitle('短视频详情'),
							},
						},
						component: lazy(() => import(/*webpackChunkName:'router-shortvideo-play-component'*/ '@/pages/Photo/Shortvideo/Play')),
					},
				],
			},
		],
	},
	{
		path: '/music',
		name: 'Music',
		meta: { breadName: '音乐', metaInfo: { title: proTitle('音乐') }, isUnfoldAll: true },
		component: lazy(() => import(/*webpackChunkName:'router-music-component'*/ '@/pages/Music')),
		children: [
			{
				path: 'sys/everyday',
				name: 'MusicSysEveryday',
				meta: {
					breadName: '每日推荐',
					metaInfo: {
						title: proTitle('音乐每日推荐'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-music-sys-everyday-component'*/ '@/pages/Music/Sys/Everyday')),
			},
			{
				path: 'sys/bgm',
				name: 'MusicSysBgm',
				meta: { breadName: '背景音乐', metaInfo: { title: proTitle('背景音乐列表') } },
				component: lazy(() => import(/*webpackChunkName:'router-music-sys-bgm-component'*/ '@/pages/Music/Sys/Bgm')),
			},
			{
				//播放列表
				path: 'playlist',
				name: 'MusicPlaylist',
				meta: { breadName: '歌单', metaInfo: { title: proTitle('歌单') } },
				component: lazy(() => import(/*webpackChunkName:'router-music-playlist-component'*/ '@/pages/Music/Playlist')),
				children: [
					{
						path: ':onlyid',
						name: 'MusicPlaylistDetail',
						meta: { breadName: '详情', metaInfo: { title: proTitle('歌单详情') } },
						component: lazy(() => import(/*webpackChunkName:'router-music-playlist_-component'*/ '@/pages/Music/Playlist/Detail')),
					},
				],
			},
			{
				//歌曲详情
				path: 'detail/:onlyid',
				name: 'MusicDetail',
				meta: { breadName: '详情', metaInfo: { title: proTitle('歌曲详情') } },
				component: lazy(() => import(/*webpackChunkName:'router-music-detail-component'*/ '@/pages/Music/Detail')),
			},
			{
				//歌曲分类
				path: 'category',
				name: 'MusicCategory',
				meta: { breadName: '分类', metaInfo: { title: proTitle('歌曲分类') } },
				component: lazy(() => import(/*webpackChunkName:'router-music-category-component'*/ '@/pages/Music/Category')),
				children: [
					{
						path: ':onlyid',
						name: 'MusicCategoryDetail',
						meta: { breadName: '详情', metaInfo: { title: proTitle('歌曲分类详情') } },
						component: lazy(() => import(/*webpackChunkName:'router-music-category_-component'*/ '@/pages/Music/Category/Detail')),
					},
				],
			},
			{
				//歌曲筛选
				path: 'tag',
				name: 'MusicTag',
				meta: { breadName: '标签', metaInfo: { title: proTitle('歌曲标签') } },
				component: lazy(() => import(/*webpackChunkName:'router-music-tag-component'*/ '@/pages/Music/Tag')),
			},
			{
				//全部歌曲
				path: 'all',
				name: 'MusicAll',
				meta: { breadName: '全部', metaInfo: { title: proTitle('全部歌曲') } },
				component: lazy(() => import(/*webpackChunkName:'router-music-all-component'*/ '@/pages/Music/All')),
			},
		],
	},
	{
		path: '/flash',
		//name: 'Flash',
		meta: { breadName: '闪客', metaInfo: { title: proTitle('闪客'), isUnfoldAll: true } },
		component: lazy(() => import(/*webpackChunkName:'router-flash-category_-component'*/ '@/pages/Flash')),
		children: [
			{
				path: 'category/:onlyid',
				name: 'FlashCategoryId',
				meta: { /*breadName: '相册列表',*/ metaInfo: { breadName: '闪客分类', title: proTitle('闪客分类') } },
				component: lazy(() => import(/*webpackChunkName:'router-album-component'*/ '@/pages/Flash/Category')),
				children: [],
			},
			{
				path: '',
				name: 'FlashCategoryAll',
				meta: {
					//breadName: '相册列表',
					metaInfo: {
						title: proTitle('闪客'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-flash-category-component'*/ '@/pages/Flash/Category')),
				children: [],
			},
			{
				path: 'play/:onlyid',
				name: 'FlashPlay',
				meta: {
					breadName: '播放',
					metaInfo: {
						title: proTitle('播放Flash'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-flash-play-component'*/ '@/pages/Flash/Play')),
			},
			{
				path: 'lab',
				name: 'FlashLab',
				meta: {
					breadName: '工具箱',
				},
				redirect: 'lab/run-localhost',
				component: lazy(() => import(/*webpackChunkName:'router-flash-lab-run-localhost-component'*/ '@/pages/Flash/Lab')),

				children: [
					{
						path: 'run-localhost',
						name: 'FlashLabRunLocalhost',
						meta: {
							breadName: '运行本地文件', //['运行', '本地文件'],
							metaInfo: {
								title: proTitle('本地Flash'),
							},
						},
						component: lazy(() => import(/*webpackChunkName:'router-flash-lab-run-localhost-component'*/ '@/pages/Flash/Lab/RunLocalhost')),
					},
					{
						path: 'action-script-test',
						name: 'FlashLabActionScriptTest',
						meta: {
							breadName: 'AS代码调试', //['运行', '本地文件'],
							metaInfo: {
								title: proTitle('ActionScript调试工具'),
							},
						},
						component: lazy(() => import(/*webpackChunkName:'router-flash-lab-run-localhost-component'*/ '@/pages/Flash/Lab/ActionScriptTest')),
					},
					{
						path: 'fanvas',
						name: 'FlashLabFanvas',
						meta: {
							breadName: 'Fanvas', //['运行', '本地文件'],
							metaInfo: {
								title: proTitle('Fanvas'),
							},
						},
						component: lazy(() => import(/*webpackChunkName:'router-flash-lab-run-localhost-component'*/ '@/pages/Flash/Lab/Fanvas')),
					},
					{
						path: 'flv-js',
						name: 'FlashLabFlvJS',
						meta: {
							breadName: 'flv.js', //['运行', '本地文件'],
							metaInfo: {
								title: proTitle('flv.js'),
							},
						},
						component: lazy(() => import(/*webpackChunkName:'router-flash-lab-run-localhost-component'*/ '@/pages/Flash/Lab/FlvJS')),
					},
				],
			},
		],
	},
	{
		path: '/links',
		name: 'Link',
		meta: {
			breadName: '友情链接',
			metaInfo: {
				title: proTitle('友情链接'),
				isUnfoldAll: true,
			},
		},
		component: lazy(() => import(/*webpackChunkName:'router-link-component'*/ '@/pages/Links')),
		children: [
			{
				path: 'friends',
				name: 'LinkFriends',
				meta: {
					breadName: '好友',
					metaInfo: {
						title: proTitle('我的好友'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-link-friends-component'*/ '@/pages/Links/Friends')),
			},
			{
				path: 'group',
				name: 'LinkGroup',
				meta: {
					breadName: '圈子',
					metaInfo: {
						title: proTitle('我的圈子'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-link-group-component'*/ '@/pages/Links/Group')),
				children: [
					{
						path: ':onlyid',
						name: 'LinkGroup_',
						meta: {
							breadName: '详情',
							metaInfo: {
								title: proTitle('圈子详情'),
							},
						},
						component: lazy(() => import(/*webpackChunkName:'router-link-group_-component'*/ '@/pages/Links/Group')),
					},
				],
			},
			{
				path: 'favorites',
				name: 'LinkFavorites',
				meta: {
					breadName: '收藏夹',
					metaInfo: {
						title: proTitle('收藏夹'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-link-friends-component'*/ '@/pages/Links/Favorites')),
			},
			{
				path: 'register',
				name: 'LinkRegister',
				meta: {
					breadName: '申请',
					metaInfo: {
						title: proTitle('申请友情链接'),
					},
				},
				component: lazy(() => import(/*webpackChunkName:'router-link-friends-component'*/ '@/pages/Links/Register')),
			},
		],
	},
	{
		path: '/link',
		redirect: '/links',
	},
	{
		path: '/friends',
		redirect: '/link/friends',
	},
	{
		path: '/about',
		name: 'About',
		meta: {
			breadName: '关于我',
			metaInfo: {
				title: proTitle('关于我'),
				isUnfoldAll: true,
			},
		},
		component: lazy(() => import(/*webpackChunkName:'router-about-component'*/ '@/pages/About')),
	},
	{
		path: '/about/guestboard',
		name: 'AboutGuestboard',
		meta: {
			breadName: '留言板',
		},
		component: lazy(() => import(/*webpackChunkName:'router-about_guestboard-component'*/ '@/pages/About/Guestboard')),
	},
	{
		path: '/about/statistics',
		name: 'AboutStatistics',
		meta: {
			breadName: '统计信息',
		},
		component: lazy(() => import(/*webpackChunkName:'router-about_statistics-component'*/ '@/pages/About/Statistics')),
	},
	{
		path: '/lab',
		//name: 'Lab',
		meta: {
			breadName: '实验室',
			metaInfo: {
				title: proTitle('实验室'),
			},
			hideBanner: true,
		},
		component: lazy(() => import(/*webpackChunkName:'router-lab-component'*/ '@/pages/Lab')),
		children: [
			{
				path: 'interface',
				name: 'LabInterface',
				meta: {
					breadName: 'API接口',
					metaInfo: {
						title: proTitle('API接口 - 实验室'),
					},
					hideBanner: true,
				},
				component: lazy(() => import(/*webpackChunkName:'router-lab-interface-component'*/ '@/pages/Lab/Interface')),
			},
			{
				path: 'project',
				name: 'LabProject',
				meta: {
					breadName: '项目',
					metaInfo: {
						title: proTitle('实验项目 - 实验室'),
					},
					hideBanner: true,
				},
				component: lazy(() => import(/*webpackChunkName:'router-lab-project-component'*/ '@/pages/Lab/Project')),
			},
			{
				path: 'effect',
				name: 'LabEffect',
				meta: {
					breadName: '网页特效',
					metaInfo: {
						title: proTitle('网页特效 - 实验室'),
					},
					hideBanner: true,
				},
				component: lazy(() => import(/*webpackChunkName:'router-lab-effect-component'*/ '@/pages/Lab/Effect')),
			},
			{
				path: 'effect-detail/:onlyid',
				name: 'LabEffectDetail',
				meta: {
					breadName: '网页特效详情',
					metaInfo: {
						title: proTitle('网页特效详情 - 实验室'),
					},
					hideBanner: true,
				},
				component: lazy(() => import(/*webpackChunkName:'router-lab-effect-detail-component'*/ '@/pages/Lab/EffectDetail')),
			},
			{
				path: 'tool',
				name: 'LabTool',
				meta: {
					breadName: 'Web工具箱',
					metaInfo: {
						title: proTitle('Web工具箱 - 实验室'),
					},
					hideBanner: true,
				},
				component: lazy(() => import(/*webpackChunkName:'router-lab-tool-component'*/ '@/pages/Lab/Tool')),
			},
			{
				path: '',
				name: 'Lab',
				meta: {
					breadName: '项目',
					metaInfo: {
						title: proTitle('实验室'),
					},
					hideBanner: true,
				},
				component: lazy(() => import(/*webpackChunkName:'router-lab-api-component'*/ '@/pages/Lab/Project')),
			},
		],
	},
	{
		path: '/search',
		name: 'Search',
		meta: {
			breadName: '搜索',
			metaInfo: {
				title: proTitle('搜索'),
			},
			hideBanner: true,
		},
		component: lazy(() => import(/*webpackChunkName:'router-search-component'*/ '@/pages/Search')),
	},
	{
		path: '*',
		name: 'Error',
		meta: {
			breadName: '错误',
			metaInfo: {
				title: proTitle('页面出错'),
			},
		},
		component: Error,
	},

	/*
{
path: "/",
name: "Home",
component: Home,
},
{
path: "/about",
name: "About",
// route level code-splitting
// this generates a separate chunk (about.[hash].js) for this route
// which is lazy-loaded when the route is visited.
component: () =>
import(/--* webpackChunkName: "about" *--/ "../views/About.vue"),
},
*/
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	linkActiveClass: 'link-active',
	linkExactActiveClass: 'link-exact-active',
	routes,
});

//页面滚动条机制
router.beforeEach((to, from, next) => {
	//document.documentElement.scrollTop = 0;
	//console.log('切换前');
	//console.log(JSON.stringify(allScrollInfo));
	const allScrollInfo = pub.get('routerScrollInfo') || {};
	//将离开的页面滚动条记录
	allScrollInfo[from.fullPath] = {
		x: document.documentElement.scrollLeft,
		y: document.documentElement.scrollTop,
	};
	pub.set('routerScrollInfo', allScrollInfo);
	store.commit('changeRouteKey');
	//console.log('去往', to, '来自', from);
	next();
});
router.afterEach(to => {
	//console.log('切换后');
	if (to.query._target || to.query.commentPage) {
		const targetEl = document.querySelector(`[data-scroll-target="${to.query._target}"]`);
		if (targetEl) {
			const y = (() => {
				let res = 0;
				let targetEl_ = targetEl;
				let i = 0;
				do {
					if (targetEl_ && (targetEl_ as HTMLElement).offsetTop) {
						res += (targetEl_ as HTMLElement).offsetTop;
						targetEl_ = (targetEl_ as HTMLElement).offsetParent!;
					}
					if (!targetEl_) {
						break;
					}
					i++;
					if (i > 100) {
						break;
					}
				} while (targetEl_ !== document.body && targetEl_ !== document.documentElement);
				/*
				res += (targetEl as HTMLElement).offsetTop;
				const parent = (targetEl as HTMLElement).offsetParent;
				if (parent) {
					res += (parent as HTMLElement).offsetTop;
				}
				*/
				res -= 60;
				return res;
			})();
			document.documentElement.scrollTop = y;
			try {
				//document.documentElement.scrollTo(0, y);
			} catch (e) {
				//
			}
		}
		return; //如果本身有目标锚点，则不执行
	}
	//触发新的滚动条机制
	const allScrollInfo = pub.get('routerScrollInfo') || {};
	const { x, y } = allScrollInfo[to.fullPath] || { x: 0, y: 0 };
	//document.documentElement?.scrollTo(x || 0, y || 0);
	//console.log(to);
	document.documentElement.scrollTop = y;
	document.documentElement.scrollLeft = x;
});

//页面标题、元信息更改机制
router.afterEach(to => {
	store.commit('changeMetaInfo', {
		...metaConfig.metaInfo,
		...(to?.meta?.metaInfo || {}),
	});
});

//解决前往相同路由时的报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: any) {
	return (originalPush.call(this, location) as any).catch((err: any) => err);
};
export default router;
