import { api as apiConfig } from '@/config';

interface Res {
	code: number;
	msg: string;
}

const api: any = {
	ALL: 'all/all',
	ALL_COMMENT: 'all/comment', //最新的评论（包括说说、文章、相册、留言板等所有）
	ABOUT_ALL: 'about/all', //关于我、关于本站、免责声明等
	ACTIVITY_ALL: 'activity/all', //全站动态，只获取30条
	//ACTIVITY_PHRASE: 'activity/phrase', //单条说说的详情(包含评论信息、点赞信息等)
	//ACTIVITY_COMMENT: 'activity/comment', //说说最新的评论（这里显示所有说说的最新评论，不是单条说说的评论）
	BLOG_PHRASE_ALL: 'blog/phrase_all', //获取所有的说说
	BLOG_PHRASE_PHRASE: 'blog/phrase_detail', //获取单个说说的详情，传参数（说说的惟一标识符onlyid）
	BLOG_ARTICLE_ALL: 'blog/article_all', //获取所有的文章，只包含博文，传参数（分类category、排序order、筛选sort、每页多少everypagenum第几页page），返回时携带共多少，以便前端做出页码判断
	BLOG_ARTICLE_ARTICLE: 'blog/article_detail', //获取单个文章的详情，传参数（文章的惟一标识符onlyid）
	BLOG_ARTICLE_AROUND: 'blog/article_around', //文章附近的文章
	BLOG_ARTICLE_BASEINFO: 'blog/article_baseinfo', //获取博客基本信息，文章数量、标签数量等……
	BLOG_ARTICLE_HOT: 'blog/article_hot', //热门文章（通常进入页面时就会加载），传参数（加载多少个num）
	BLOG_ARTICLE_RANDOM: 'blog/article_random', //随便看看，传参数（加载多少个num）
	BLOG_ARTICLE_CATEGORY: 'blog/article_category', //获取所有的分类，包含一级分类、二级分类等
	BLOG_ARTICLE_CALENDAR: 'blog/article_calendar', //获取当前日历下的文章数，传参数（当前月month或起始时间starttime,endtime，是否获取详情getdetail(否则只获取数量)）
	BLOG_ARTICLE_COMMENT: 'blog/article_comment',
	BLOG_ARTICLE_COMMENT_RANK: 'blog/article_comment_rank', //留言/评论的好友排行榜
	BLOG_ARTICLE_TAG: 'blog/article_tag', //获取所有文章的标签
	BLOG_ARTICLE_TAG_ALL: 'blog/article_tag_all', //获取所有文章的标签
	BLOG_ARTICLE_ARCHIVE_SIMP: 'blog/article_archive_simp',
	BLOG_ARTICLE_PRIMARY: 'blog/article_primary', //文章点赞功能
	PHOTO_PHOTO_ALBUM_ALL: 'photo/photo_album_all', //获取所有的相册
	PHOTO_PHOTO_ALBUM: 'photo/photo_album', //获取相册内的所有照片，传参数（排序order、每页多少张everypagenum、第几页page）返回时携带共多少张，以便前端做出页码判断，每张照片给出粗略信息
	PHOTO_PHOTO_PHOTO: 'photo/photo_photo', //获取单张照片的详细信息
	PHOTO_PHOTO_HOT: 'photo/photo_hot', //获取最热门的图片
	//废弃PHOTO_TIMELINE_ALL: 'photo/timeline_all', //相册时间线，获取所有的时间线，//传参数（当前月month或起始时间starttime,endtime，第几页page）
	PHOTO_TIMELINE_ALL: 'photo/timeline_all', //相册时间线，获取所有的时间线，传参数range日期范围，字符串yyyymmdd-yyyymmdd
	PHOTO_TIMELINE_DETAIL: 'photo/timeline_detail', //相册时间线，获取该日期下的所有图片，传参数date:字符串yyyymmdd
	PHOTO_TIMELINE_RECENT: 'photo/timeline_recent', //自动获取时间线内最近的照片
	PHOTO_SHORTVIDEO_ALL: 'photo/shortvideo_all', //获取所有的短视频，传参数（每页多少条everypagenum第几页）
	PHOTO_SHORTVIDEO_HOT: 'photo/shortvideo_hot', //热门短视频，传参数，获取多少条num
	PHOTO_SHORTVIDEO_RANDOM: 'photo/shortvideo_random', //随机短视频，传参数，获取多少条num
	PHOTO_SHORTVIDEO_DETAIL: 'photo/shortvideo_detail', //
	PHOTO_SHORTVIDEO_LOVE: 'photo/shortvideo_love', //
	PHOTO_COMMENT: 'photo/comment', //相册最新的评论，传参数，获取多少条num
	MUSIC_BGM: 'music/bgm', //获取背景音乐的列表，及播放模式(随机？循环？)等
	MUSIC_SLIDESHOW: 'music/slideshow', //音乐界面的轮播图
	MUSIC_EVERYDAY: 'music/everyday', //获取音乐的每日推荐
	MUSIC_LIST_ALL: 'music/list_all', //音乐的歌单(携带两个固定歌单，每日推荐和背景音乐)
	MUSIC_LIST: 'music/list', //音乐歌单的详情
	MUSIC_SONG: 'music/song', //歌曲的详情
	MUSIC_CATEGORY_ALL: 'music/category_all', //音乐分类，原创、参与创作的、网友作品、翻唱、音乐收藏
	MUSIC_CATEGORY: 'music/category', //音乐分类的详情
	MUSIC_LIST_HOT: 'music/list_hot', //热门歌单
	MUSIC_TAG_ALL: 'music/tag_all', //获取所有的音乐标签 ，返回值例如：流派【】，心情【】等等……
	MUSIC_TAG: 'music/tag',
	MUSIC_LEAGUE_ALL: 'music/league_all', //音乐排行榜，获取所有的榜单，及简略的歌曲信息（热歌、新歌、飙升榜等）
	MUSIC_LEAGUE: 'music/league', //获取指定排行榜的详细信息
	FLASH_ALL: 'flash/all', //获取所有的闪客动画
	FLASH_HOT: 'flash/hot', //获取热门的FLASH
	FLASH_LOVE: 'flash/love',
	FLASH_CATEGORY_ALL: 'flash/category_all', //获取flash的分类，mv、小游戏、小程序、素材
	FLASH_CATEGORY: 'flash/category', //获取分类下的flash
	FLASH_FLASH: 'flash/flash', //获取flash的详情，传参数onlyid
	LINK_FRIENDS_ALL: 'link/friends_all', //获取所有的朋友
	LINK_FRIENDS_ADD: 'link/friends_add', //申请添加好友(申请友链)
	LINK_FRIENDS_HOT: 'link/friends_hot', //热门点击的好友
	LINK_CIRCLE_ALL: 'link/circle_all', //获取所有的圈子
	LINK_CIRCLE_VALIDATE: 'link/circle_validate', //获取所有的圈子
	LINK_CIRCLE_CIRCLE: 'link/circle_circle', //获取圈子里面的成员
	LINK_FAVORITE_ALL: 'link/favorite_all', //获取所有的网址收藏(根据登录状态鉴权)
	//GUESTBOOK_WISH: 'guestbook/wish', //留言板的主人寄语
	GUESTBOOK_ALL: 'guestbook/all', //留言板的所有内容（包括主人寄语）
	GUESTBOOK_ADD: 'guestbook/add', //发布留言
	STATISTICS_ALL: 'statistics/all', //获取所有的运行状态
	STATISTICS_VISITOR: 'statistics/visitor', //获取访客的详细信息
	STATISTICS_SERVER: 'statistics/server', //获取服务器的详细信息

	SEARCH: 'search', //获取搜索结果，传参数关键词keyword，类型type（所有？博客？相册？），每页多少条everypagenum，第几页page
	SEARCH_HOT: 'search/hot', //热门搜索
	ERROR_REPORTING: 'report', //报错信息

	//通信交互部分
	COMMUNICATION: 'communication',

	//实验室部分
	LAB_WEB_EFFECT_ALL: 'lab/web_effect_all',
	LAB_WEB_EFFECT_DETAIL: 'lab/web_effect_detail',

	//页面交互部分
	SEND_COMMENT: 'stage/send_comment', //发表评论（包含回复等内容，但不包含留言）
	SEND_LIKE: 'stage/send_like', //点赞
	SEND_SHARE: 'stage/send_share', //分享
	API_QQ_GETUSER: 'stage/get_qq_user', //根据QQ号获取信息

	//页面信息图表部分
	VE_VISITOR_DETAIL: 'app_data/visitor',
	VE_BLOGCATEGORY_DETAIL: 'app_data/blog_article_category',
	VE_PAYLOAD_DETAIL: 'app_data/payload',
};
const apiPostMethod: any[] = ['API_QQ_GETUSER', 'SEND_COMMENT', 'SEND_LIKE', 'SEND_SHARE', 'GUESTBOOK_ADD', 'LINK_FRIENDS_ADD', 'COMMUNICATION'];

//定义接口地址
const { prefix, suffix } = apiConfig;
const strToUrl = (value: string): string => {
	return `${prefix}${value}${suffix}`;
};
//输入键值，输出对应URL
export const getApiUrl = (key: string): string | Res => {
	if (typeof api[key] === 'string') {
		return strToUrl(api[key]);
	} else {
		//console.warn('无效的API键值');
		if (key) {
			return {
				code: 2,
				msg: `无法获取请求源:${key}`,
			};
		} else {
			return '';
		}
	}
};
export const getApiMethod = (key: string): string => {
	if (apiPostMethod.includes(key)) {
		return 'POST';
	} else {
		return 'GET';
	}
};

export default { getApiUrl, getApiMethod };
