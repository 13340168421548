//import { Base64 } from 'js-base64';
import CryptoJS from 'crypto-js';

export const asyncComponent = (path: string): any => {
	return () =>
		import(path)
			.then(r => r)
			.catch(() => {
				alert('断网了？？？');
			});
};

export const getLength = (value: any): number => {
	if (!value) {
		return 0;
	}
	return value.length;
};

interface ParseTimeOptions {
	isGeneral?: boolean;
	isOnlyDateSimp?: boolean;
}
export const parseTime = (time: number, options: ParseTimeOptions = {}): string => {
	if (time === -1) {
		return '-';
	}
	time *= 1000; //将后端的时间戳转换为前端时间戳
	//将时间戳转化为实例对象
	const oTime = new Date(time);
	const strDate = `${oTime.getFullYear()}-${oTime.getMonth() + 1}-${oTime.getDate()}`;
	const strTime = `${('' + oTime.getHours()).padStart(2, '0')}:${('' + oTime.getMinutes()).padStart(2, '0')}`;
	if (typeof options === 'object' && !!options.isGeneral) {
		const diffTime = new Date().getTime() - time;
		const diffDate = Math.floor((new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000) / 86400000) - Math.floor((time - new Date().getTimezoneOffset() * 60 * 1000) / 86400000);
		//console.log(diffDate);
		if (diffTime <= 60000) {
			//一分钟内显示刚刚
			return '刚刚';
		} else if (diffTime <= 600000) {
			//十分钟内显示
			return `${Math.ceil(diffTime / 60000)}分钟前`;
		} else if (diffDate <= 2) {
			//三天之内显示
			return `${['今天', '昨天', '前天'][diffDate]} ${strTime}`;
		}
	}
	if (typeof options === 'object' && !!options.isOnlyDateSimp) {
		return `${oTime.getDate()}日 ${('' + oTime.getHours()).padStart(2, '0')}:${('' + oTime.getMinutes()).padStart(2, '0')}`;
	}
	return `${strDate} ${strTime}`;
	//:${('' + oTime.getSeconds()).padStart(2, '0')}
	//return '';
};

export const generateOnlyid = (): string => Math.floor(Math.random() * 4294967295).toString(16);

export const encode = (str: string): string => {
	//const encodeStr = Base64.encode(str).replaceAll('i', '[').replaceAll('w', 'i').replaceAll('[', 'w');
	//return encodeStr;
	//return str;
	const ciphertext = (CryptoJS as any)?.AES.encrypt(str, 'a218q5re41qw58r9').toString();
	return ciphertext;
};
export const decode = (str: string): string => {
	//const decodeStr = Base64.decode(str.replaceAll('i', '[').replaceAll('w', 'i').replaceAll('[', 'w'));
	//return decodeStr;
	//eturn str;
	const bytes = (CryptoJS as any)?.AES.decrypt(str, 'a218q5re41qw58r9');
	const originalText = bytes.toString((CryptoJS as any).enc.Utf8);
	return originalText;
};
export const showSecretNumber = (str: string | number, showStart = 1, showEnd = 2): string => {
	str = '' + str;
	if (str.length <= showStart + showEnd) {
		return str;
	}
	return str.substring(0, showStart) + new Array(str.length - showStart - showEnd).fill('*').join('') + str.substring(str.length - showEnd);
};
export const isUrl = (str: any): boolean => {
	if (typeof str !== 'string') {
		return false;
	}
	if (str.indexOf('http://') === 0) {
		return true;
	} else if (str.indexOf('https://') === 0) {
		return true;
	}
	return false;
};
export const mergeArrayForNumArr = (arr: Uint8Array, targetNum: number): number[] => {
	const every = arr.length / targetNum;
	if (isNaN(every)) {
		return [];
	}
	const targetArr = new Array(targetNum).fill(0);
	arr.forEach((item, index) => {
		const i = Math.floor(index / every);
		targetArr[i] += Number(item) || 0;
	});
	return targetArr;
};
