import Vue from 'vue';
import CompositionApi from '@vue/composition-api';
import { Plugin as Fragment } from 'vue-fragment';
import VueMeta from 'vue-meta';
//import VueI18n from 'vue-i18n';
import VueShowdown from 'vue-showdown';
import VueLazyload from 'vue-lazyload';
//import VuePhotoPreview from 'vue-photo-preview';
import VuePhotoPreview from '@mvpleung/vue-photo-preview';

//import highlight from 'highlight.js';
//import 'highlight.js/styles/default.css';

//import { Message } from '@/components/UI';
//import { Message } from 'element-ui';
//import enLocale from 'element-ui/lib/locale/lang/en'
//import zhLocale from 'element-ui/lib/locale/lang/zh-CN'

import Root from './Root.vue';
//import './registerServiceWorker';
import router from './router';
import store from './store';
import metaInfo from './router/meta-info';
import { i18n } from '@/i18n';

//引入一些插件的配置文件
import VueLazyLoadConfig from '@/config/vue-lazy-load';
import VuePhotoPreviewConfig from '@/config/vue-photo-preview';
//import VuePhotoPreviewConfig from '@mvpleung/vue-photo-preview';
//import VueI18nConfig from '@/config/i18n';

//import 'tailwindcss/tailwind.css'
import '@/assets/css/main.scss';

import 'vue-photo-preview/dist/skin.css';

//使用基本的API
Vue.config.productionTip = false;
Vue.use(CompositionApi);
Vue.use(Fragment);
//Vue.use(Message);
//Vue.prototype.$message = (Message as any).install;

//使用源
Vue.use(VueMeta);
//使用多语言
//Vue.use(VueI18n);
//Vue.use(i18n);
//const i18n = new VueI18n(VueI18nConfig);
//const { t } = (VueI18n as any).useI18n();
//Vue.prototype.$t = Vue.prototype.$t.bind(Vue.prototype);

//Vue.config.lang = 'zh-cn';
//Vue.locale('zh-cn',zhLocale);
//Vue.locale('en', enLocale);

//Vue.prototype.$ELEMENT = { size: 'small' }
//Vue.use(Button);

//使用markdown转换器
Vue.use(VueShowdown, {
	flavor: 'github',
	emoji: true,
});
//使用代码高亮
/*
Vue.directive('highlight', function (el) {
    const blocks = el.querySelectorAll('pre code');
    blocks.forEach((block: any) => {
        highlight.highlightBlock(block);
    });
});
*/
//使用图片懒加载
Vue.use(VueLazyload, VueLazyLoadConfig);
//使用图片预览器
Vue.use(VuePhotoPreview as any, VuePhotoPreviewConfig);

//console.log('before');
(window as any).__preventBrowserRender__ ||
	new Vue({
		router,
		store,
		i18n,
		metaInfo,
		render: h => h(Root),
	}).$mount(document.querySelector('div#root')!);

//console.log('after');

//npx prettier --write --config .prettierrc src/*.ts

