import { consoleDeterOutput as consoleDeterOutputConfig } from '@/config';

const isConsoleOpen = (): boolean => {
    //返回控制台是否被打开
    let res = false;
    //根据正则判断
    const reg = /\//;
    (reg as any).toString = function () {
        res = true;
    };
    console.table({ reg });

    //根据函数判断
    function _() {
        /* */
    }
    let fCount = 0;
    _.toString = function () {
        fCount++;
    };
    console.info(_);
    fCount === 2 && (res = true);

    //根据div判断
    const oDiv = document.createElement('div');
    (oDiv as any).toString = function () {
        res = true;
    };

    //console.clear();
    return res;
};
const game = () => {
    //推荐悠悠博客
    console.log(
        '%c本站感谢悠悠博客提供了很多设计灵感。悠悠游大漂亮，一个集美貌、智慧于一体的完美女神，作为村里唯一的希望，前端后端通吃，拥有着各种全网最牛逼的尖端技术，无论UI设计、敲代码、打游戏、写小说还是颜值都处于万人仰慕的无敌状态，是我在网页制作方面最崇拜的大神，控制台回复【悠悠博客】即可访问！',
        "background-color:#3a3a3a;color:#ededed;padding:1rem;font-size:18px;font-family:'xiane';line-height:1.5;text-indent:2em;border:8px solid #78491f" //background:radial-gradient(circle farthest-corner at 5% 95%, #FFEDF2, #ECF2FF);
    );
    //控制台通过密码打开后，发送游戏邀请
    /*
    console.log(
        `%c前世五百次回眸换来今生擦肩而过，能够遇到你真是三生有幸。既然来了，咱们来就做个游戏，如何？%c\n\n%ca.小测试\nb.剧本杀\nc.棋牌类\nd.真心话大冒险\nz.面试题\n%c(回复对应字母开始游戏)`,
        'padding:8px;border-radius:3px;background-color:rgba(237,30,121,0.1);line-height:1.5;font-size:13px;font-family:\'xiane\'',
        'color:gray;font-weight:bold;font-family:\'xiane\'',
        'font-size:13px;font-weight:normal;line-height:1.5;font-family:\'xiane\'',
        'color:lightgray;font-family:\'xiane\''
    );
    */
    const selectOption = {
        a: '选项A',
        b: '选项B',
    };
    const select = (opt: any) => {
        /*
        switch(opt){
            case 'a':
                console.log('当前A选项');
                break;
            case 'b':
                console.log('当前B选项');
                break;
            case 'c':
                console.log('当前C选项');
                break;
            case 'd':
                console.log('当前D选项');
                break;
            default:
                console.log('错误');
        }
        */
        /*
        axios.get('/api/article/allwithdesc').then(response => {
            let {data} = response;
            if(data.code === 0){
                console.log(data.data[0].title);
            }else{
                console.log(`%c错误:${data.code}`,'background-color:yellow');
            }
        },error => {
            console.log('很抱歉，网络错误，游戏无法继续');
        });
        return db.line;//`--------------------------------------`;
        */
        setTimeout(() => {
            console.log('opt:' + opt);
        }, 1);
        return opt + ':' + (selectOption as any)[opt];
    };
    const put = ['a', 'b', 'c', 'd', 'z'];
    for (const k in put) {
        (() => {
            const item = put[k];
            if (!(item in window)) {
                try {
                    Object.defineProperty(window, item, {
                        get: () => select(item),
                    });
                } catch (e) {
                    //
                }
            }
        })();
    }
    try {
        Object.defineProperty(window, '悠悠博客', {
            get() {
                window.open('https://www.usuuu.com/?from=sheaneh.com');
                return 'https://www.usuuu.com/';
            },
        });
    } catch (e) {
        //
    }
};

//let isOpenConsole = false;

const consoleLock = () => {
    setTimeout(game, 1);
    /*
    setInterval(() => {
        const open = isConsoleOpen();
        if (isOpenConsole !== open) {
            isOpenConsole = open;
            if (isOpenConsole) {
                //document.body.innerHTML = '好奇害死猫';
            } else {
                //
                document.location.reload();
            }
        }
    }, 1000);
    */

    if (consoleDeterOutputConfig.isLock) {
        //如果控制台上锁
        const debuggerFunction = new Function(
            '/*\r\n\t本站已禁用控制台调试\r\n\t请关闭开发人员工具以便正常访问\r\n*/\r\ndebugger;'
        );
        let beforeLockTime = new Date().getTime();
        let debuggerInterval: number | undefined = undefined;
        const fn = () => {
            debuggerFunction();
            const afterLockTime = new Date().getTime();
            if (afterLockTime - beforeLockTime > 2000) {
                clearInterval(debuggerInterval);
                document.body.innerHTML =
                    '<div style="display:flex;flex-direction:column;justify-content:center;align-items:center;height:100vh;width:100%;"><h4 style="margin:0.5em;font-size:2rem;">好奇害死猫</h4><a href="javascript:/*是的,点这里就可以正常访问了*/;" onclick="window.location.reload()" style="color:rgba(127,127,127,.5);">关闭控制台后点这里<!--我已关闭控制台--></a></div>';
                return;
            }
            beforeLockTime = afterLockTime;
        };
        debuggerInterval = setInterval(fn, 500);
    }
};

export default consoleLock;
