import { defineComponent } from '@vue/composition-api';

import SvgEmpty from './svg-empty';

export default defineComponent({
	props: {
		text: {
			type: String,
			default: '', //暂无内容
			required: false,
		},
	},
	components: {
		SvgEmpty,
	},
	setup(props, { root }: any) {
		const t = root.$t.bind(root);
		//const showText = props.text || t('loading');
		return { t };
	},
});
