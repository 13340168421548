import Vue from 'vue';
import Tip from './Tip.vue';

const tipBox = Vue.extend(Tip as any);

(Tip as any).install = function (options: any, type: any) {
	if (typeof options === 'undefined' || !options) {
		options = {
			content: '',
		};
	} else if (typeof options === 'string' || typeof options === 'number') {
		options = {
			content: '' + options,
		};
		//if (type !== undefined && options !== null) {
		//}
	}
	//console.log(type);
	options.type = ['info', 'bigInfo'].includes(type) ? type : 'info';
	const instance = new tipBox({
		data: options,
	}).$mount();
	const tipContainer =
		document.querySelector('div[data-shui-tip]') ||
		(() => {
			const tipContainer = document.createElement('div');
			tipContainer.setAttribute('data-shui-tip', '');
			tipContainer.setAttribute('class', 'shui-tip-package');
			document.body.appendChild(tipContainer);
			return tipContainer;
		})();
	tipContainer.innerHTML = '';
	tipContainer.appendChild(instance.$el);

	//console.log(tipContainer);
	//alert('提示信息' + instance.$el);
	Vue.nextTick(() => {
		instance.visible = true;
	});
};
(Tip as any).install.info = (options: any) => {
	(Tip as any).install(options, 'info');
};
(Tip as any).install.bigInfo = (options: any) => {
	(Tip as any).install(options, 'bigInfo');
};

//console.log('调用了tip组件');

if (!Vue.prototype.$tip) {
	Vue.prototype.$tip = (Tip as any).install;
}

export default Tip;
