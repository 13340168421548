interface Introduce {
    title: string;
    url: string;
}
interface ConsoleDeterOutput {
    have: string[];
    regExp: RegExp[];
    isRewrite: boolean;
    reportInterval: number;
    isErrorHidden: boolean;
    isLock?: boolean;
}
interface Router {
    ext: string;
}
interface App {
    isImmediateOnLine: boolean;
    isCopyCarryCopyright: boolean;
}
interface MusicPlayer {
    isAllowPlayingBackground: boolean;
}
interface Api {
    prefix: string;
    suffix: string;
}
interface Meta {
    titleSuffix: string;
    metaInfo: MetaInfo;
}
interface MetaInfo {
    title: string;
    keywords: string | string[];
    description: string;
}

export const introduce: Introduce = {
    title: '仙娥小站',
    url: 'https://sheaneh.com',
};
export const consoleDeterOutput: ConsoleDeterOutput = {
    //阻止控制台输出
    have: [
        'New Ruffle instance created',
        'Loading SWF',
        'Ruffle instance destroyed',
    ],
    regExp: [/^Loading SWF/, /Ruffle instance/, /Vue/],
    isRewrite: false,
    reportInterval: 2000, //向后端发送错误报告的间隔时间
    isErrorHidden: false, //隐藏错误和警告信息
    isLock: false, //控制台上锁
};
export const router: Router = {
    ext: 'html',
};
export const app: App = {
    isImmediateOnLine: false, //是否实时检查断网状态
    isCopyCarryCopyright: true, //是否在复制的时候携带版权
};
export const musicPlayer: MusicPlayer = {
    isAllowPlayingBackground: true, //播放器是否后台播放
};
export const api: Api = {
    prefix: '/api/',
    suffix: '/',
};
export const meta: Meta = {
    titleSuffix: ' - 仙娥小站',
    metaInfo: {
        title: '仙娥小站 - 个人博客空间',
        keywords: '仙娥小站,个人网站,个人博客,网站建设,个人博客空间',
        description: '记录生活，发现美好',
    },
};
export default {
    introduce,
    consoleDeterOutput,
    router,
    app,
    //api,
    //meta,
};
