




import { defineComponent, getCurrentInstance, onMounted, Ref, ref } from '@vue/composition-api';

export default defineComponent({
	setup() {
		const hash: Ref<string | number> = ref(Math.random());
		const run = () => {
			try {
				const { proxy } = getCurrentInstance()!;
				const { $preview } = proxy as any;
				const touchPreview = (e: any, item: any) => {
					//console.log('触发了图片预览,当前哈希', hash.value);
					//_preview.close();
					//preventBack();
					const _preview = $preview.self;
					history.pushState(null, null as any, document.URL);
					const preventBack = (event: any) => {
						history.pushState(null, null as any, document.URL);
						_preview.close();
					};
					window.addEventListener('popstate', preventBack);
					_preview.listen('close', () => {
						//console.log('触发了关闭');
						window.removeEventListener('popstate', preventBack);
						$preview.on('imageLoadComplete', touchPreview);
					});
				};
				$preview.on('imageLoadComplete', touchPreview);
			} catch (e) {
				//console.error('图片预览工具的控制器发生错误', e);
			}
		};
		onMounted(run);
		return { hash };
	},
});
