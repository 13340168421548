import { defineComponent, ref, watch } from '@vue/composition-api';
import type { Ref } from '@vue/composition-api';

import Navbar from '@/components/Navbar';
import Banner from '@/components/Banner';
import VueRouter from 'vue-router';

export default defineComponent({
	name: 'PageHeader',
	components: {
		Navbar,
		Banner,
	},
	setup(props, { root }: any) {
		const { $router } = root;
		const isNormally: Ref<boolean> = ref(false);
		$router.afterEach((to: any) => {
			isNormally.value = false;
			if (typeof to === 'object') {
				if (typeof to.meta === 'object') {
					if (to.meta.hideBanner) {
						isNormally.value = true;
					}
				}
			}
		});
		return { isNormally };
	},
});
