import { ref, reactive, toRefs, onMounted, onBeforeUnmount } from '@vue/composition-api';
import type { Ref, Data } from '@vue/composition-api';

import objectFitImages from 'object-fit-images'; //图像比例自适应兼容IE

import PageNodeMain from '@/components/PageNodeMain/index.vue';
import PageNodeSub from '@/components/PageNodeSub/index.vue';

import Card from '@/components/Card';
import Profile from '@/components/Article/Profile';
import Activity from '@/components/Article/Activity';
import FriendsLite from '@/components/Article/FriendsLite';
import HotBlog from '@/components/Article/HotBlog';
import HotPhoto from '@/components/Article/HotPhoto';

export default {
	components: {
		PageNodeMain,
		PageNodeSub,
		Card,
		Profile,
		Activity,
		FriendsLite,
		HotBlog,
		HotPhoto,
	},
	setup(props: any, { root }: any) {
		const t = root.$t.bind(root);
		const data: Data = reactive({});
		onMounted(() => {
			if (/Trident/.test(navigator.userAgent)) {
				(objectFitImages as any)(document.querySelectorAll('[data-image-object-fit-compat-ie]'));
			}
		});
		onBeforeUnmount(() => {
			//
		});
		return {
			...toRefs(data),
			t,
		};
	},
};
