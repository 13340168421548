import { defineComponent, reactive, Ref, ref } from '@vue/composition-api';

import { Skeleton } from '@/components/UI';

import FetchData from '@/components/FetchData';

import store from '@/store';

interface FriendsData {
	avatar: string;
	key: string | number;
	nickname?: string;
	title?: string;
	description: string;
	url: string;
}

export default defineComponent({
	components: { Skeleton, FetchData },
	setup() {
		const data: Ref<FriendsData[]> = ref([
			{
				avatar: store.state.limitImgUrl,
				key: '@@---',
				title: '网名',
				description: '这是一条描述77777777777777',
				url: '',
			},
			{
				avatar: store.state.limitImgUrl,
				key: '@@---+',
				title: '这是另外一位朋友',
				description: '这是一条描述',
				url: '',
			},
		]);
		/*
        ref([
			{
				avatar: '/static/testimg/uu.jpg?https://www.usuuu.com/assets/user.1984c151.jpg',
				key: 'asdequwie',
				nickname: '悠悠',
				description: '村里唯一的希望',
				url: 'https://www.usuuu.com/',
			},
			{
				avatar: '/static/testimg/toubiec.png?https://img.toubiec.cn/bizhi/logo.png',
				key: 'oisdfuiwssseiu',
				nickname: '晓晴',
				description: '小鸟的一生就只有一次飞行的机会',
				url: 'https://www.toubiec.cn/',
			},
			{
				avatar: 'https://www.huxianbk.cn/wp-content/uploads/2021/11/QQ%E5%9B%BE%E7%89%8720210929182503.jpg?https://www.huxianbk.cn/wp-content/uploads/2021/10/QQ%E5%9B%BE%E7%89%8720210929182503.jpg',
				key: 'oisdfuiweiu',
				nickname: '小狐仙',
				description: '喜欢一个人的感觉是不管你在干什么心里都在想着他',
				url: 'https://www.huxianbk.cn/',
			},
			{
				avatar: 'https://tcxx.info/wp-content/themes/StarryW/images/bg/me.jpg',
				key: 'podsiweruire',
				nickname: '甜菜欣欣',
				description: '我不是天才，我只是甜菜',
				url: 'https://www.tcxx.info/',
			},
			{
				avatar: '/static/testimg/avatar.webp',
				key: 'innei',
				nickname: 'Innei',
				description: '这是我的小世界呀',
				url: 'https://innei.ren/',
			},
			{
				avatar: 'https://q1.qlogo.cn/g?b=qq&nk=80360650&s=100',
				key: 'qew4ttge',
				nickname: '若志',
				description: '愿世界安康，愿你我皆好！',
				url: 'https://www.rz.sb/',
			},
			{
				avatar: 'https://cdn.jsdelivr.net/gh/kannimade/tu/9ec49b207f3350abe6de8d554d880733.png',
				key: 'ad',
				nickname: '阿呆',
				description: '我在人间凑数的日子',
				url: 'https://bo.ke/',
			},
		]);*/
		const touchData = (friendsData: FriendsData[]) => (data.value = friendsData);
		return { data, touchData };
	},
});
